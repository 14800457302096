







import { Component, Vue } from 'vue-property-decorator';
import AuthenticationService from '@/services/AuthenticationService';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';
import MChatBotHelpCrunch from '@/components/molecules/MChatBotHelpCrunch.vue';

@Component({
  components: { MChatBotHelpCrunch }
})
export default class App extends Vue {
  loading: boolean = false;
  ready: boolean = false;

  async created () {
    this.listenForPWANewVersion();
    if (AuthenticationService.getAccessJWT()) {
      try {
        this.loading = true;
        await AuthenticationStore.reFetchCurrentUser();
        this.loading = false;
      } catch (e: any) {
        console.error(e);
      }
      this.ready = true;
    } else {
      this.ready = true;
    }
  }

  listenForPWANewVersion () {
    // This even is called from the registerServiceWorker.ts when a new version is discovered.
    EventBus.$on(EventBusEvents.EV_NEW_APP_DOWNLOADED, 'App.vue', () => {
      this.$buefy.dialog.confirm({
        canCancel: false,
        confirmText: this.$t('dict.ok') as string,
        message: this.$t('newReleaseAvailable.message') as string,
        onConfirm: () => {
          window.location.reload();
        },
        onCancel: () => {
          window.location.reload();
        },
        trapFocus: true,
        type: 'is-primary',
        title: this.$t('newReleaseAvailable.title') as string
      });
    });
  }
}
