import { SnackbarProgrammatic as Snackbar } from 'buefy';
import { BNoticeComponent } from 'buefy/types/components';

const snackbars: BNoticeComponent[] = [];

let addTimeout;
export default () => {
  return (errorMessage: string, err: any) => {
    snackbars.forEach((snackbar) => {
      snackbar.close();
    });
    clearTimeout(addTimeout);
    addTimeout = setTimeout(() => {
      console.log('PUSHING THE SNACK');
      snackbars.push(
        // Now just directly add the snackbar to the stack opened,
        // allowing us to close again later
        Snackbar.open({
          duration: 5000,
          message: errorMessage,
          type: 'is-danger',
          actionText: 'OK',
          position: 'is-bottom-left',
          indefinite: true,
          queue: false
        })
      );
    }, 1000);
    console.error(err);
  };
};
