/* Styles */
import '@/styles/index.scss';

// Import the vue and global plugins/filters
import Vue from 'vue';
import Buefy from 'buefy';
import VueGoogleCharts from 'vue-google-charts';
import { i18n } from '@/plugins/i18n';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
import './class-component-hooks';
import { extend, localize, setInteractionMode } from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import rules from '@/plugins/veevalidate/rules';

// Import our actual app.
import '@/registerServiceWorker';
import Auth from '@/services/AuthenticationService';
import App from '@/components/App.vue';
import router from '@/router';
import store from '@/store';
import ApiHelper from '@/services/ApiHelper';

import VueFeatherIconPack from '@/components/atoms/icon/AVueFeatherIconPack.vue';
import buefyVueFeatherIcons from '@/constantsAndEnums/buefyVueFeatherIcons';

// Import the flags css
import '/node_modules/flag-icons/css/flag-icons.min.css';

import TimeAgo from 'javascript-time-ago';
import timeAgoEn from 'javascript-time-ago/locale/en';

TimeAgo.addDefaultLocale(timeAgoEn as any);

Vue.component('vue-feather-icon-pack', VueFeatherIconPack);

// Prod tip off
// Vue.config.productionTip = false;

// Use plugins and filters
Vue.use(VueGoogleCharts);
Vue.use(Buefy, {
  defaultTabsAnimated: false,
  defaultIconComponent: 'vue-feather-icon-pack',
  defaultIconPack: 'vfi',
  customIconPacks: { ...buefyVueFeatherIcons }
});
Vue.use(VueFilterDateFormat);

// configuration
ApiHelper.setupAxios();
Object.keys(rules).forEach(rule => extend(rule, rules[rule]));
localize('en', en);
setInteractionMode('eager');

const init = () => {
  new Vue({
    i18n,
    router,
    store,
    render: (h) => h(App),
  }).$mount('#app');
};

Auth.reAuthorise().then(() => {
  init();
}).catch((e) => {
  console.log('Unknown Error re-establishing auth', e);
  Auth.clearAllLocalData();
  init();
});

if (module.hot) {
  module.hot.accept();

  module.hot.addStatusHandler(status => {
    if (status === 'prepare') {
      console.clear();
    }
  });
}
