export enum InjectType {
  google = 'Google',
  facebook = 'Facebook'
}

enum InjectTypeSrc {
  Google = 'https://apis.google.com/js/platform.js',
  Facebook = 'https://connect.facebook.net/en_US/sdk.js'
}

export const ssoScriptInjector = (injectedId, type: InjectType): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (document.getElementById(injectedId)) {
      return resolve();
    }

    const scriptElm = document.createElement('script');
    console.log(type);
    scriptElm.src = InjectTypeSrc[type];
    scriptElm.async = true;
    scriptElm.defer = true;
    scriptElm.id = injectedId;
    scriptElm.onload = () => resolve();
    scriptElm.onerror = function () {
      alert(`Your browser is blocking ${type} scripts from loading. 

If you would like to continue with ${type} you must let your browser run ${type} scripts.
This is different on each browser but is typically due to a browser extension or security setting.

If you cannot continue with ${type} why not try with your email instead.
`);
      reject();
    };
    // @ts-ignore
    scriptElm.crossorigin = 'anonymous';
    document.body.appendChild(scriptElm);
  });
};