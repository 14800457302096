











import { Component, Vue, Watch } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';
import EventBus, { EventBusEvents } from '@/EventBus';
import config, { Env } from '@/config';

@Component
export default class MChatBotHelpCrunch extends Vue {
  scriptId: string = 'helpcrunch_script';
  eventsCallerId = 'MChatBotHelpCrunch';
  initialised: boolean = false;

  get user () {
    return AuthenticationStore.getCurrentUser;
  }

  @Watch('$route')
  routeChange () {
    if (this.initialised) {
      this.showOrHide();
    }
  }

  async created () {
    this.eventsBind();
    await this.setup();
  }

  beforeDestroy () {
    this.eventsUnbind();
    this.tearDown();
  }

  eventsBind () {
    EventBus.$on(EventBusEvents.EV_LOGIN, this.eventsCallerId, () => {
      this.updateUser();
      this.showOrHide();
    });
    EventBus.$on(EventBusEvents.EV_LOGOUT, this.eventsCallerId, this.tearDown);
  }

  eventsUnbind () {
    EventBus.$remove(EventBusEvents.EV_LOGIN, this.eventsCallerId);
    EventBus.$remove(EventBusEvents.EV_LOGOUT, this.eventsCallerId);
  }

  showOrHide () {
    const landing = this.$route.matched.some((record) => record.meta.showLiveChat);
    window.HelpCrunch && window.HelpCrunch(landing ? 'showChatWidget' : 'hideChatWidget');
  }

  shouldItLoad () {
    // only run this on production
    if (config.env !== Env.production) {
      return false;
    }

    // don't load when the script tag is already there on screen
    const alreadyLoaded = document.getElementById(this.scriptId) as Element;
    return !alreadyLoaded;
  }

  async setup () {
    if (!this.shouldItLoad()) {
      return;
    }
    window.HelpCrunch = function () {
      window.HelpCrunch.q.push(arguments);
    };
    window.HelpCrunch.q = [];
    const s = document.createElement('script');
    s.type = 'text/javascript';
    s.onload = () => {
      this.init();
    };
    s.id = this.scriptId;
    s.src = 'https://widget.helpcrunch.com/';
    document.head.appendChild(s);
  }

  init () {
    const initObject: any = {
      applicationId: 1,
      applicationSecret: '8GsD0ctom64XishJwJYPK8ASnByrMQwxw/lzO+4WKVtEc8xxCDCqr7hMsVZqwMF651HP6/HT7QRy/MooB9c49Q==',
    };
    if (this.user.email) {
      initObject.user = this.buildUserObj();
    }
    try {
      window.HelpCrunch('init', 'liffery', initObject);
      window.HelpCrunch('onReady', () => {
        this.initialised = true;
        this.showOrHide();
      });
    } catch (e) {
      console.log('Help crunch init error:', e);
    }
  }

  buildUserObj () {
    return {
      email: this.user.email,
      user_id: this.user.username,
      name: this.user.firstName + ' ' + this.user.lastName,
    };
  }

  updateUser () {
    if (this.user.email) {
      window.HelpCrunch && window.HelpCrunch('updateUser', 'liffery', this.buildUserObj());
    }
  }

  tearDown () {
    const cScript = document.getElementById(this.scriptId) as Element;
    if (!cScript) {
      return;
    }
    cScript.remove();
    const crispEls = document.querySelectorAll('.helpcrunch-iframe-wrapper');
    crispEls.forEach((el: Element) => {
      el.remove();
    });
    delete window.HelpCrunch;
  }
}
