
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AResizableTextareaWrapper extends Vue {
  @Prop()
  resetHeight?: boolean;

  initialHeight: number = 0;

  mounted () {
    this.$nextTick(() => {
      this.initialHeight = this.$el.scrollHeight;
      this.setInitialHeight();
      this.$el.addEventListener('input', this.resizeTextarea);
    });
  }

  destroyed () {
    this.$el.removeEventListener('input', this.resizeTextarea);
  }

  setInitialHeight () {
    this.$el.setAttribute(
      'style',
      'height:' + this.initialHeight + 'px; overflow-y:hidden;'
    );
  }

  resizeTextarea (event) {
    event.target.style.height = 'auto';
    event.target.style.height = event.target.scrollHeight + 'px';
  }

  render () {
    return this?.$slots?.default?.length && this.$slots.default[0];
  }

  @Watch('resetHeight', { immediate: true })
  resetHeightHandle (newValue) {
    if (newValue) {
      this.setInitialHeight();
    }
  }
}
