import Cookies from 'js-cookie';
import config from '../config';
import jwtDecode from '@/utils/jwtDecode';
import getDateInXMonths from '@/utils/getDateInXMonths';
import getDateInXDays from '@/utils/getDateInXDays';
import HttpService from '@/services/HttpService';
import WebSocket from '@/socket/WebSocket';
import ApiHelper from '@/services/ApiHelper';
import { AuthenticationStore, clearAll } from '@/store';

const RenewalTokenKey = config.cookie.renew;
const AccessTokenKey = config.cookie.access;

class AuthenticationService {
  /**
   * ReInjects the access jwt into the store from cookie storage.
   * Injects the jwt state of the user into the store
   * Used on app initial load
   */
  async reAuthorise (): Promise<void> {
    let accessJWT = this.getAccessJWT();
    try {
      const renewed = await ApiHelper.renewJWTAccess();
      accessJWT = (renewed) ? renewed : undefined;
      if (accessJWT) {
        this.injectAccessJWT(accessJWT);
      } else {
        this.clearAllLocalData();
      }
    } catch (e: any) {
      this.clearAllLocalData();
    }
  }

  clearAllLocalData () {
    console.log('Clearing local data');
    this.ejectAccessJWT();
    this.ejectRenewalJWT();
    clearAll();
    localStorage.clear();
    localStorage.removeItem('vuex');
  }

  /**
   * Gets the access JWT token from cookie storage
   */
  getAccessJWT (): string | undefined {
    return Cookies.get(AccessTokenKey);
  }

  injectAccessJWT (token: string): void {
    Cookies.set(AccessTokenKey, token, {
      expires: getDateInXDays(1),
      secure: this.makeCookieSecure(),
      sameSite: this.makeCookieSecure() ? 'None' : 'Lax'
    });
    HttpService.headers = { Authorization: 'Bearer ' + token };
    WebSocket.connect(`${config.api.baseWss}`, token);
  }

  doesJWTRequireRenewal (encodedToken?: string, minAgeInSeconds = 5): boolean {
    if (encodedToken) {
      const decodedToken = jwtDecode(encodedToken);
      if (decodedToken.exp) {
        const maxAge = (new Date()).getTime() + minAgeInSeconds * 1000;
        const tokenExpMS = (new Date(decodedToken.exp * 1000)).getTime();
        if (tokenExpMS <= maxAge) {
          return true;
        }
      }
    }
    return false;
  }

  doesRenewalJWTRequireRenewal (): boolean {
    return this.doesJWTRequireRenewal(this.getRenewalJWT(), 10 * 60);
  }

  doesAccessJWTRequireRenewal (): boolean {
    return this.doesJWTRequireRenewal(this.getAccessJWT());
  }

  ejectAccessJWT (): void {
    delete HttpService.headers.Authorization;
    Cookies.remove(AccessTokenKey);
  }

  getRenewalJWT (): string | undefined {
    return Cookies.get(RenewalTokenKey);
  }

  injectRenewalJWT (token: string): void {
    Cookies.set(RenewalTokenKey, token, {
      expires: getDateInXMonths(18),
      secure: this.makeCookieSecure(),
      sameSite: this.makeCookieSecure() ? 'None' : 'Lax'
    });
  }

  ejectRenewalJWT (): void {
    Cookies.remove(RenewalTokenKey);
  }

  makeCookieSecure (): boolean {
    return window.location.protocol === 'https:';
  }

  logout () {
    AuthenticationStore
      .logout()
      .then(() => {
        // Force full redirect
        window.location.href = '/';
      })
      .catch((e) => {
        console.error(e);
        // Force full redirect
        window.location.href = '/';
      });
  }
}

export default new AuthenticationService();
