














import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class AButtonSubmit extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  @Prop({default: ''})
  size!: string

  @Prop({ default: false })
  loading!: boolean;

  @Prop({ default: '' })
  title!: string;

  @Prop()
  buttonClasses!: string;
}
