import { User } from '@/api/ms-authentication/services/interfaces';
import { permittedRoles } from '@/constantsAndEnums/permittedRoles';

export default (user: User) => {
  if (!user.roles) {
    return false;
  }
  for (let i = 0; i < user.roles.length; ++i) {
    if (permittedRoles.includes(user.roles[i])) {
      return true;
    }
  }
  return false;
}
