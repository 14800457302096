import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import NotificationService from '@/api/ms-notification/services/NotificationService';
import { Datum as Notification } from '@/api/ms-notification/services/interfaces/Notifications';
import { State } from '@/api/ms-notification/services/interfaces/NotificationPatch';
import {
  INotificationsModule,
  NotificationTypes,
  patchNotifications
} from '@/store/modules/interfaces/NotificationsModule';

@Module({
  name: StoreNames.NOTIFICATIONS_STORE,
  namespaced: true,
})
export default class NotificationsModule extends VuexModule implements INotificationsModule {
  @Mutation
  RESET () {
  }

  notifications: NotificationTypes = {
    header: [],
    page: []
  };

  get getHeaderNotifications (): Notification[] {
    return this.notifications.header;
  }

  get getPageNotifications (): Notification[] {
    return this.notifications.page;
  }

  get getUnseenStateCount (): number {
    return this.notifications.header.filter((n: Notification) => {
      const lastState = n.interactionStates.pop();
      return (lastState) ? [State.Seen, State.Interacted].includes(lastState.state) : false;
    }).length;
  }

  @Action({ rawError: true })
  async getLatestHeader () {
    const { data } = await NotificationService.notificationGet({
      limit: 5
    });
    this.SET_NOTIFICATION_HEADER(data);
  }

  @Action({ rawError: true })
  async getLatestPage () {
    const { data } = await NotificationService.notificationGet({});
    this.SET_NOTIFICATION_PAGE(data);
  }

  @Action
  async patchNotifications (input: patchNotifications) {
    if (!Array.isArray(input.notifications)) {
      input.notifications = [input.notifications];
    }
    const ids = input.notifications.map((notif) => {
      return String(notif._id);
    });
    if (ids && ids.length > 0) {
      const newState = {
        state: input.newState,
        date: new Date()
      };
      const updated = await NotificationService.notificationPatch({
        updateIds: ids,
        interactionState: newState
      });
      this.INJECT_UPDATED_NOTIFICATIONS(updated.data);
    }
  }

  @Mutation
  INJECT_NEW_NOTIFICATION (input: Notification) {
    this.notifications.header.unshift(input);
  }

  @Mutation
  SET_NOTIFICATION_HEADER (input: Notification[]) {
    this.notifications.header = input;
  }

  @Mutation
  SET_NOTIFICATION_PAGE (input: Notification[]) {
    this.notifications.page = input;
  }

  @Mutation
  INJECT_UPDATED_NOTIFICATIONS (notifications: Notification[]) {
    // loop the existing notifications
    this.notifications.header.forEach((notification: Notification, index: number) => {
      // find a matching one in the given arr
      notifications.forEach((newNotification: Notification) => {
        if (notification._id === newNotification._id) {
          this.notifications.header.splice(index, 1, newNotification);
        }
      });
    });
  }
}
