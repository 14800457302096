







































































































import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationStore } from '@/store';

import AUserProfilePic from '@/components/atoms/AUserProfilePic.vue';
import ARouterLinkLogout from '@/components/atoms/link/ARouterLinkLogout.vue';
import ARouterLinkSettings from '@/components/atoms/link/ARouterLinkSettings.vue';
import ARouterLinkSecurity from '@/components/atoms/link/ARouterLinkSecurity.vue';
import { ChevronDownIcon, LockIcon } from 'vue-feather-icons';
import ARouterLinkLiffery from '@/components/atoms/link/ARouterLinkLiffery.vue';
import ARouterLinkLifferyContact from '@/components/atoms/link/ARouterLinkLifferyContact.vue';
import OLanguageChangeModal from '@/components/organisms/OLanguageChangeModal.vue';

@Component({
  components: {
    OLanguageChangeModal,
    ARouterLinkLifferyContact,
    ARouterLinkLiffery,
    AUserProfilePic,
    ARouterLinkLogout,
    ARouterLinkSettings,
    ARouterLinkSecurity,
    ChevronDownIcon,
    LockIcon
  },
})
export default class MUserProfileHeader extends Vue {
  languageSwitcherOpen = false;

  get user () {
    return AuthenticationStore.user;
  }

  get currentLanguage () {
    return this.$i18n.locale;
  }

  localeToISOA2 (locale: string): string {
    return locale.slice(-2).toLowerCase();
  }
}
