























import { Component, Prop, Vue } from 'vue-property-decorator'
import BInput from 'buefy/src/components/input/Input.vue'
import { AAutocompleteAsyncData } from '@/components/atoms/AAutocompleteInterfaces'

@Component({
  components: {
    BInput,
  }
})
export default class AAutocomplete extends Vue {
  @Prop()
  asyncFunction: any
  @Prop({ default: 'Search...' })
  placeholder!: string
  @Prop({ default: 'magnify' })
  icon!: string

  loading: boolean = false
  timeout: any
  data: AAutocompleteAsyncData[] = []

  handleSearch (qs: string) {
    if (this.timeout) {
      clearTimeout(this.timeout)
    }
    this.timeout = setTimeout(() => {
      this.loading = true
      this.asyncFunction(qs).then((data: AAutocompleteAsyncData[]) => {
        this.data = []
        data.forEach((item: AAutocompleteAsyncData) => this.data.push(item))
        this.loading = false
      })
    }, 600)
  }

  emitEvent (option) {
    this.$emit('child-output', option)
  }
}
