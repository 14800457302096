import HttpService from '@/services/HttpService';

import { CaptchaGet } from './interfaces/CaptchaGet';
import { CaptchaGetQuery } from './interfaces/CaptchaGetQuery';
import { CaptchaPost } from './interfaces/CaptchaPost';
import { GenericMessage } from './interfaces/GenericMessage';

export default class CaptchaService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: captchaGet
   *  Summary: Get captcha
   *  Description: Gets a captcha for the user to solve
   */
  public static captchaGet(query: CaptchaGetQuery): Promise<CaptchaGet> {
    return HttpService.sendRequest({
      method: 'GET',
      path: CaptchaService.basePath + 'captcha',
      qs: query,
    });
  }

  /**
   *  Operation ID: captchaPost
   *  Summary: Create a captcha
   *  Description: Validate a captcha request and respond with 200 on success
   */
  public static captchaPost(body: CaptchaPost): Promise<GenericMessage> {
    return HttpService.sendRequest({
      method: 'POST',
      path: CaptchaService.basePath + 'captcha',
      body,
    });
  }
}
