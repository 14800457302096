import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';
import beforeEach from './beforeEach';
import afterEach from './afterEach';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }

    return { x: 0, y: 0 };
  },
});
router.beforeEach(beforeEach);
router.afterEach(afterEach);
export default router;
