import HttpService from '@/services/HttpService';

import { GenericMessage } from './interfaces/GenericMessage';

export default class LogoutService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: logoutGet
   *  Summary: Get logout
   *  Description: Logs current user out of the system
   */
  public static logoutGet(): Promise<GenericMessage> {
    return HttpService.sendRequest({
      method: 'GET',
      path: LogoutService.basePath + 'logout',
    });
  }
}
