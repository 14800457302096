import Cookies, { CookieAttributes } from 'js-cookie';

export default class Cookie {
  static async set (key: string, value: string, options: CookieAttributes) {
    Cookies.set(key, value, options);
  }

  static async get (key: string) {
    return Cookies.get(key);
  }

  static async remove (key: string) {
    Cookies.remove(key);
  }
}
