export default async () => {
  if (window.parent) {
    setTimeout(() => {
      const body = document.querySelectorAll('body');
      window.parent.postMessage({
        bodyHeight: body[0].scrollHeight
      }, '*');
    }, 1000); // we wait 1 sec here to allow the page to re-render any content
  }
};
