export interface NotificationPatch {
  interactionState: InteractionState;
  updateIds: string[];
}

export interface InteractionState {
  /**
   * Timestamp of the current state of the notification
   */
  date: Date;
  /**
   * Notification state enum
   */
  state: State;
}

/**
 * Notification state enum
 */
export enum State {
  Interacted = 'interacted',
  Seen = 'seen',
  Unseen = 'unseen',
}
