




























import { Component, Vue } from 'vue-property-decorator';
import { SSOFb } from '@/utils/SSOFb';
import { AuthenticationStore } from '@/store';

import AFacebookIcon from '@/components/atoms/icon/AFacebookIcon.vue';
import { RouteNames } from '@/router/RouteNames';

@Component({
  components: {
    AFacebookIcon
  }
})
export default class AFacebookButton extends Vue {
  originalMsg = this.$t('sso.facebook');
  message = this.originalMsg;

  async signIn () {
    try {
      this.message = this.$t('dict.loading') + '...';
      const resp = await SSOFb();
      await AuthenticationStore.loginWithFacebook(resp);
      await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
    } catch (e) {
      this.message = this.originalMsg;
    }
  }
}
