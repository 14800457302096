


































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { EyeIcon, EyeOffIcon } from 'vue-feather-icons';

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    EyeIcon,
    EyeOffIcon
  }
})
export default class APasswordInputWithValidation extends Vue {
  @Prop({ required: true })
  value!: any;
  @Prop()
  label!: string;
  @Prop({ required: false })
  name!: string;
  @Prop({ default: 'off' })
  autoComplete!: string;
  @Prop()
  nameAlt?: string;
  @Prop()
  placeholder?: string;
  @Prop()
  vid?: string;
  @Prop()
  rules?: string;
  @Prop()
  groupFieldClass?: string;

  innerValue = '';
  showPassword = false;

  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }

  @Watch('innerValue')
  innerValueHandle (newVal) {
    this.$emit('child-output', newVal);
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueValueHandle (newVal) {
    this.innerValue = newVal;
  }
}
