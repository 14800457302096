
















































































import { Component, Vue } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { DomainValidationCheckPost } from '@/api/ms-business-account-mgmt/services/interfaces';
import { BusinessAccountStore } from '@/store';
import DomainService from '@/api/ms-business-account-mgmt/services/DomainService';

import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import { CheckSquareIcon, DownloadIcon } from 'vue-feather-icons';
import downloadPngFile from '@/utils/downloadPngFile';

@Component({
  components: {
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver,
    ValidationProvider,
    CheckSquareIcon,
    DownloadIcon
  }
})
export default class ODomainValidation extends Vue {
  hash: string = '';

  form: DomainValidationCheckPost = {
    path: ''
  };
  isLoading = false;
  isSaving = false;
  serverError: any = null;
  serverSuccess: any = null;

  get selectedDomain () {
    return BusinessAccountStore.getSelectedDomain;
  }

  async created () {
    await this.getValidationFile();
  }

  async getValidationFile () {
    this.isLoading = true;
    const { hash } = await DomainService.domainIdValidationCheckGet({ id: this.selectedDomain?._id as string });
    this.hash = hash;
    this.isLoading = false;
  }

  downloadPngFile () {
    const filename = this.hash + '.png';
    downloadPngFile(
      '/img/icons/logo-128x128.png',
      filename
    );
  }

  downloadTextFile () {
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.hash));
    element.setAttribute('download', 'liffery-verification.txt');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  async onSubmit () {
    this.isSaving = true;
    const path = new URL(this.form.path);
    try {
      await DomainService.domainIdValidationCheckPost(
        { path: path.pathname },
        { id: this.selectedDomain?._id as string }
      );
      this.$buefy.dialog.alert({
        title: this.$tc('domainVerification.title') as string,
        message: this.$tc('domainVerification.successMessage') as string,
        onConfirm: () => {
          window.location.reload();
        }
      });
      await BusinessAccountStore.fetchAccountsAndDomainsAndSet();
    } catch (e: any) {
      this.serverSuccess = null;
      switch (e.request.status) {
        case 403:
          this.serverError = 'domainVerification.error403';
          break;
        case 404:
          this.serverError = 'domainVerification.error404';
          break;
        case 409:
          this.serverError = 'domainVerification.error409';
          break;
        default:
          this.serverError = 'domainVerification.unknownError';
          break;
      }
      setTimeout(() => {
        this.serverError = null;
      }, 6000);
    }
    this.isSaving = false;
  }
}
