import { io, Socket } from 'socket.io-client';
import { NotificationsStore } from '@/store';
import serviceWorkerUnregisterAll from '@/utils/serviceWorkerUnregisterAll';

class WebSocket {
  static socket: Socket;

  static connect (connectionUrl: string, jwt: string) {
    if (WebSocket.socket && WebSocket.socket.connected) {
      return;
    }
    const socket = io(connectionUrl, {
      transports: ['websocket']
    });
    socket.on('connect', () => {
      socket
        .emit('authenticate', { token: jwt })
        .on('authenticated', () => {
          console.log('Socket connected');
          WebSocket.socket = socket;
          socket.on('direct', (data) => {
            WebSocket.eventHandler(data);
          });
        })
        .on('unauthorized', (msg) => {
          console.log(`unauthorized: ${JSON.stringify(msg.data)}`);
          throw new Error(msg.data.type);
        });
    });
    socket.on('disconnect', () => {
      console.log('Socket connection disconnected');
    });
  }

  static disconnect () {
    WebSocket.socket.disconnect();
  }

  static eventHandler (data: {
    time: string,
    routingKey: string,
    payload: any
  }) {
    switch (data.routingKey) {
      case 'msNotificationNotificationCreatedPublish':
        NotificationsStore.INJECT_NEW_NOTIFICATION(data.payload);
        break;
      case 'sosDestroyCache':
        serviceWorkerUnregisterAll();
        break;
    }
  }
}

export default WebSocket;
