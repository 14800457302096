import HttpService from '@/services/HttpService';
import objectReduceByMap from 'object-reduce-by-map';

import { GenericMessage } from './interfaces/GenericMessage';
import { GenericPass } from './interfaces/GenericPass';
import { UserOwnsOrManagesDomainGetQuery } from './interfaces/UserOwnsOrManagesDomainGetQuery';

export default class UserService {
  public static basePath = '/__/ms-business-account-mgmt/';

  /**
   *  Operation ID: userIpGet
   *  Summary: Get the current IPv4 of the user
   *  Description:
   */
  public static userIpGet(): Promise<GenericMessage> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/ip',
    });
  }

  /**
   *  Operation ID: userIsDomainOwnerOrManagerGet
   *  Summary: Get all domains related to user
   *  Description: Get all domains related to a user or if company admin with correct permissions
   */
  public static userIsDomainOwnerOrManagerGet(): Promise<GenericPass> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/is-domain-owner-or-manager',
    });
  }

  /**
   *  Operation ID: userOwnsOrManagesDomainGet
   *  Summary: Simple true false if the jwt owns or manages a given origin
   *  Description:
   */
  public static userOwnsOrManagesDomainGet(
    query: UserOwnsOrManagesDomainGetQuery
  ): Promise<GenericPass> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/owns-or-manages-domain',
      qs: query,
    });
  }
}
