

















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import { DomainPost } from '@/api/ms-business-account-mgmt/services/interfaces/DomainPost';
import { Account, Domain } from '@/api/ms-business-account-mgmt/services/interfaces';
import { BusinessAccountStore, NonPersistedStateStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';

import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import DomainService from '@/api/ms-business-account-mgmt/services/DomainService';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import TCreateAccount from '@/components/templates/TCreateAccount.vue';
import OBusinessAccountCreate from '@/components/organisms/forms/OBusinessAccountUpsert.vue';
import OBusinessAccountUpsert from '@/components/organisms/forms/OBusinessAccountUpsert.vue';
import UserService from '@/api/ms-business-account-mgmt/services/UserService';
import NewLineConverter from '@/services/NewLineConverter';
import DefaultEmptyObjects from '@/services/DefaultEmptyObjects';

@Component({
  components: {
    OBusinessAccountUpsert,
    OBusinessAccountCreate,
    TCreateAccount,
    AButtonSubmit,
    MInputWithValidation,
    AFormServerError,
    ValidationObserver,
    ValidationProvider
  }
})
export default class ODomainUpsert extends Vue {
  @Prop()
  domainUpdating?: Domain;

  @Prop({ default: false })
  hideAccountSelection!: boolean;
  routeNames = RouteNames;
  usersCurrentIPv4: string = '';
  loading = false;
  tabNames = ['showOwnItems', 'showFriendsItems', 'showOthersItems'];
  serverDuplicateError: any = null;
  permittedIp: string = '';
  form: DomainPost = DefaultEmptyObjects.defaultNewDomain;

  get showValidationModal (): boolean {
    return this.accountsOwned.length === 0;
  }

  get integrationMode (): boolean {
    return NonPersistedStateStore.getIntegrationModeOn;
  }

  get integrationModeDisabledMsg (): string {
    return NonPersistedStateStore.integrationMode.onDisabledMessage;
  }

  get getSelectedAccount (): Account {
    return BusinessAccountStore.getSelectedAccountForDomain;
  }

  get accountsOwned () {
    return BusinessAccountStore.getAccountsOwned;
  }

  @Watch('domainUpdating')
  handleDomainChange () {
    this.setup();
  }

  async created () {
    await this.setup();
  }

  async setup () {
    await BusinessAccountStore.fetchAccounts();
    const ip = await UserService.userIpGet();
    this.usersCurrentIPv4 = ip.message as string;

    if (this.domainUpdating) {
      this.form = this.domainUpdating;
      this.permittedIp = this.form.permittedIps ? NewLineConverter.toNl(this.form.permittedIps) : '';
    } else {
      this.form.origin = '';
      this.form.accountId = this.getSelectedAccount._id;
      this.permittedIp = '';
      this.form.permittedIps = [this.usersCurrentIPv4];
    }
  }

  async onSubmit () {
    this.loading = true;
    this.form.permittedIps = NewLineConverter.toArray(this.permittedIp);
    try {
      if (this.domainUpdating?._id) {
        await BusinessAccountStore.updateDomain({
          domain: this.form,
          id: this.domainUpdating?._id
        });
      } else {
        const domain = await DomainService.domainPost(this.form);
        await BusinessAccountStore.fetchDomains();
        this.$emit('child-output', domain);
      }
      this.$buefy.toast.open({
        message: 'Saved!',
        type: 'is-success'
      });
    } catch (e: any) {
      console.log(Object.keys(e), e);
      if (e.request.status === 409) {
        this.serverDuplicateError = { serverDuplicateError: 'errors.serverDuplicateError' };
      }
    }
    this.loading = false;
  }

  isCreateAccountPage (): boolean {
    return RouteNames.ROUTE_CREATE_ACCOUNT === this.$route.name;
  }

  handleMessageClosed () {
    this.serverDuplicateError = null;
  }
}
