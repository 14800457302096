






































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { MainAddress } from '@/api/ms-business-account-mgmt/services/interfaces/Account';

@Component
export default class MAddressCard extends Vue {
  @Prop({ required: true })
  address!: MainAddress;
}
