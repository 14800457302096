export enum Env {
  develop = 'develop',
  stage = 'stage',
  production = 'production'
}

export interface IConfig {
  api: {
    baseUrl: string,
    baseUrlClickout: string,
    baseWss: string,
    basePaths: {
      imageRead: string,
      clickout: string,
    }
  },
  sso: {
    facebookAppId: string,
    googleClientId: string,
  },
  keys: {
    gmaps: string
  },
  cookie: {
    access: string,
    renew: string,
  },
  env: Env,
  version: string,
  legal: {
    privacyLink: string,
    termsLink: string
  }
}

const config: IConfig = {
  api: {
    baseUrl: process.env.VUE_APP_BASE_URL,
    baseUrlClickout: process.env.VUE_APP_BASE_URL.replace('https://www.', 'https://ww.'),
    baseWss: process.env.VUE_APP_BASE_WSS,
    basePaths: {
      imageRead: '/__/ms-image-server-cache/image',
      clickout: '/__/ms-clickout/'
    }
  },
  cookie: {
    access: 'at',
    renew: 'rt',
  },
  sso: {
    facebookAppId: '904119163395754',
    googleClientId: '804679820469-u4hoq9lqq1if02v9cofn75q5vj8tls01.apps.googleusercontent.com',
  },
  keys: {
    gmaps: process.env.VUE_APP_GOOGLE_MAPS_KEY
  },
  env: process.env.NODE_ENV,
  version: process.env.VUE_APP_VERSION,
  legal: {
    privacyLink: 'https://help.liffery.com/docs/liffery-privacy-policy/',
    termsLink: 'https://help.liffery.com/docs/liffery-terms-and-conditions-of-use/'
  }
};

export default config;
