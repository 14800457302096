import HttpService from '@/services/HttpService';

import { GenericMessage } from './interfaces/GenericMessage';
import { Login } from './interfaces/Login';
import { RegisterEmailPost } from './interfaces/RegisterEmailPost';
import { RegisterEmailVerifyGetQuery } from './interfaces/RegisterEmailVerifyGetQuery';
import { RegisterEmailVerifyPost } from './interfaces/RegisterEmailVerifyPost';

export default class RegisterService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: registerEmailPost
   *  Summary: Create a email, from register
   *  Description: Register a new user account
   */
  public static registerEmailPost(body: RegisterEmailPost): Promise<Login> {
    return HttpService.sendRequest({
      method: 'POST',
      path: RegisterService.basePath + 'register/email',
      body,
    });
  }

  /**
   *  Operation ID: registerEmailVerifyGet
   *  Summary: Get verify, from email, from register
   *  Description:
   */
  public static registerEmailVerifyGet(
    query: RegisterEmailVerifyGetQuery
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'GET',
      path: RegisterService.basePath + 'register/email/verify',
      qs: query,
    });
  }

  /**
   *  Operation ID: registerEmailVerifyPost
   *  Summary: Create a verify, from email, from register
   *  Description: Verifiy the email given during registeration by providing a secret hash
   */
  public static registerEmailVerifyPost(
    body: RegisterEmailVerifyPost
  ): Promise<GenericMessage> {
    return HttpService.sendRequest({
      method: 'POST',
      path: RegisterService.basePath + 'register/email/verify',
      body,
    });
  }
}
