















































































import { Component, Vue } from 'vue-property-decorator';
import { BusinessAccountStore } from '@/store';
import ODomainUpsert from '@/components/organisms/forms/ODomainUpsert.vue';
import { RouteNames } from '@/router/RouteNames';
import OBusinessAccountUpsert from '@/components/organisms/forms/OBusinessAccountUpsert.vue';
import MChatBot from '@/components/molecules/MChatBot.vue';
import ONavbar from '@/components/organisms/ONavbar.vue';
import { Account } from '@/api/ms-business-account-mgmt/services/interfaces';

@Component({
  components: { ONavbar, MChatBot, OBusinessAccountUpsert, ODomainUpsert }
})
export default class TCreateAccount extends Vue {
  activeStep = 0;
  isLoading = true;
  ready = false;

  get getSelectedAccount (): Account {
    return BusinessAccountStore.getSelectedAccountForDomain;
  }

  async created () {
    try {
      await BusinessAccountStore.fetchAccounts();
    } catch (e: any) {
      // nothing to do
    }
    if (BusinessAccountStore.accounts.length > 0) {
      this.activeStep = 1;
    }
    this.ready = true;
    this.isLoading = false;
  }

  step1CompleteHandler () {
    this.activeStep = 1;
  }

  step2CompleteHandler () {
    this.activeStep = 2;
  }

  async completed () {
    await BusinessAccountStore.fetchAccountsAndDomainsAndSet();
    await this.$router.push({
      name: RouteNames.ROUTE_DASHBOARD
    });
  }
}
