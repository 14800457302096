import { translation } from '@/plugins/i18n/Translation';
import { AuthenticationStore } from '@/store';
import { Language } from '@/api/ms-authentication/services/interfaces/User';
import setHtmlLang from '@/utils/setHtmlLang';

export default async (language: Language, bypassPersist = false) => {
  try {
    await translation.changeLanguage(language);
    if (AuthenticationStore.getAuthenticated && !bypassPersist) {
      await AuthenticationStore.updateUserLanguage(language);
    }
    setHtmlLang(language);
  } catch (e) {
    // TODO: handle error
    console.error(e);
  }
};
