import { NavigationGuardNext, Route } from 'vue-router/types/router';
import { AuthenticationStore, BusinessAccountStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';
import userIsPermittedToLoadDashboard from '@/utils/userIsPermittedToLoadDashboard';
import DefaultEmptyObjects from '@/services/DefaultEmptyObjects';

export default async (to: Route, from: Route, next: NavigationGuardNext) => {
  const redirectTo = (route: RouteNames) => {
    return next({ name: route });
  };
  const routeRequiresAuth = to.matched.some((record) => record.meta.auth);
  const routeForAnonymousOnly = to.matched.some((record) => record.meta.onlyAnon);
  const forDomain = to.matched.some((record) => record.meta.forDomain);
  const currentUser = AuthenticationStore.getCurrentUser;

  // if the user is authenticated, and we have not initialised the defaults from the server
  if (currentUser.username && !DefaultEmptyObjects.fetched) {
    await DefaultEmptyObjects.setup();
  }

  if (routeForAnonymousOnly) {
    // the route is for anon only but the user is logged in, redirect to the dashboard
    if (currentUser.username) {
      return redirectTo(RouteNames.ROUTE_DASHBOARD);
    }
  } else if (routeRequiresAuth) {
    // route requires auth but the user is not logged int
    if (!currentUser.username) {
      return redirectTo(RouteNames.ROUTE_LOGIN);
    }

    const permitUserWithNoRole = to.matched.some((record) => record.meta.allowNoRole);

    // If the user is not permitted to load the dash and doesn't have the role, they should create an account
    if (!userIsPermittedToLoadDashboard(currentUser) && !permitUserWithNoRole) {
      return redirectTo(RouteNames.ROUTE_CREATE_ACCOUNT);
    }

    // lastly, check the domain selected is verified
    if (
      !BusinessAccountStore.getSelectedDomain?.validated &&
      forDomain &&
      ![RouteNames.ROUTE_VALIDATE_INSTALL, RouteNames.ROUTE_CONFIG].includes(to.name as RouteNames)
    ) {
      return redirectTo(RouteNames.ROUTE_VALIDATE_INSTALL);
    }
  }
  return next();
};
