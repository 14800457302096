import axios, { AxiosError } from 'axios';

import error500 from '@/storybook-components/src/utils/error500';
import error401 from '@/utils/error401';
import Auth from './AuthenticationService';
import AuthenticationService from './AuthenticationService';
import TokenService from '@/api/ms-authentication/services/TokenService';
import HttpService from '@/services/HttpService';
import { AuthenticationStore } from '@/store';
import { i18n } from '@/plugins/i18n';
import InternetStatus from '@/services/InternetStatus';

const error500caller = error500();

export default class ApiHelper {

  /**
   * Global axios setup
   * - request interceptors
   * - response interceptors
   * NB: The jwt access header is injected automatically within the http service
   */
  static setupAxios () {
    InternetStatus.setup();
    // Check we have an updated renewal 1st as this will be needed to
    axios.interceptors.request.use(ApiHelper.checkJwtTokens, (e) => Promise.reject(e));
    axios.interceptors.request.use(ApiHelper.noInternet, (e) => Promise.reject(e));
    axios.interceptors.response.use((response) => response, ApiHelper.noInternetResponse);
    axios.interceptors.response.use((response) => response, ApiHelper.handle401);
    axios.interceptors.response.use((response) => response, ApiHelper.handle500);
  }

  static async checkJwtTokens (axiosConfig) {
    // only run this check if we are logged in else there will not be a token to check
    if (axiosConfig.headers.Authorization) {
      // Ensure we are not calling the route to actually renew the renewal
      if (axiosConfig.url.indexOf('token/renew/renewal') === -1) {
        axiosConfig = await ApiHelper.renewRenewalJWTCheck(axiosConfig);
      }
      // Ensure we are not calling the route to actually renew the access
      if (axiosConfig.url.indexOf('token/renew/access') === -1) {
        axiosConfig = await ApiHelper.renewAccessJWTCheck(axiosConfig);
      }
    }
    return axiosConfig;
  }

  /**
   * Checks if the renewal token should be renewed and does so
   * if required.
   * @param axiosConfig
   */
  static async renewRenewalJWTCheck (axiosConfig) {
    if (!Auth.doesRenewalJWTRequireRenewal()) {
      return axiosConfig;
    }
    const renewalJWT = await Auth.getRenewalJWT();
    try {
      const data = await TokenService.tokenRenewRenewalPost({ renewalToken: renewalJWT as string });
      if (data && data.tokenRenewal) {
        // Inject the new renewal key then proceed
        await Auth.injectRenewalJWT(data.tokenRenewal.value);
        return axiosConfig;
      }
      // catchall logout
      await AuthenticationStore.logout(true);
    } catch (e) {
      console.error('Error renewing renewal token, logging out', e);
      await AuthenticationStore.logout(true);
    }
  }

  /**
   * Renews jwt token if before sending requests if required
   * @param axiosConfig
   */
  static async renewAccessJWTCheck (axiosConfig) {
    if (axiosConfig.headers.Authorization && axiosConfig.url.indexOf('token/renew/access') === -1) {
      if (Auth.doesAccessJWTRequireRenewal()) {
        delete axiosConfig.headers.Authorization;
        try {
          const key = await ApiHelper.renewJWTAccess();
          if (key) {
            axiosConfig.headers.Authorization = 'Bearer ' + key;
            return axiosConfig;
          } else {
            return axiosConfig;
          }
        } catch (e) {
          console.error('Error renewing renewal token, logging out', e);
          await AuthenticationStore.logout(true);
        }
      } else {
        return axiosConfig;
      }
    } else {
      return axiosConfig;
    }
  }

  static async renewJWTAccess (): Promise<string | void> {
    const renewalJWT = await Auth.getRenewalJWT();
    HttpService.headers = {};
    if (renewalJWT) {
      const key = await AuthenticationStore.renewAccessToken({ renewalToken: renewalJWT });
      if (key) {
        await AuthenticationService.injectAccessJWT(key);
        return key;
      }
    }
  }

  static noInternet (axiosConfig) {
    if (!InternetStatus.isOnline) {
      error500caller(i18n.t('errors.noConnection') as string, 'No internet connection');
      // now throw an error to bubble up the stack
      throw new Error('No internet');
    }
    return axiosConfig;
  }

  static noInternetResponse (err: AxiosError) {
    if (err.message === 'Network Error') {
      if (!navigator.onLine) {
        return error500caller(i18n.t('errors.noConnection') as string, err);
      }
    }
    throw err;
  }

  /**
   * Checks if the error type is a 401 and attempts to renew token if present
   * @param err
   */
  static async handle401 (err: AxiosError) {
    if (err.code && err.code === 'ERR_CANCELED') {
      return;
    }
    if (err.request.status === 401
      && err.config
      // @ts-ignore
      && !err.config.__isRetryRequest
      && err.config.headers && err.config.headers.Authorization // only throw the handler if the user was meant to be logged in
      && err.request.responseURL.indexOf('token/renew/access') === -1
      && err.request.responseURL.indexOf('token/renew/renewal') === -1
    ) {
      return error401(err);
    }
    throw err;
  }

  /**
   * Checks for error 500
   * @param err
   */
  static handle500 (err: AxiosError) {
    if (err.request && err.request.status === 500) {
      console.log('ERROR CAUGHT');
      console.error(err);
      error500caller(i18n.t('errors.server500') as string, err);
      return;
    }
    throw err;
  }
}
