




import { Component, Prop, Vue } from 'vue-property-decorator'
import config from '@/config'
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath'

@Component
export default class AImage extends Vue {
  @Prop(String)
  private altText
  @Prop(String)
  private imagePath
  @Prop({
    type: String,
    validator (value: any): boolean {
      return (Object.values(Size).includes(value))
    }
  })
  private size
  @Prop(String)
  private backup
  @Prop({ default: '' })
  className!: string;

  get fullHref (): string {
    const parts = this.imagePath.split('/');
    parts.splice(parts.length - 1, 0, this.size);
    return config.api.baseUrl + config.api.basePaths.imageRead + '/' + parts.join('/');
  }
}
