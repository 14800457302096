









import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { LockIcon } from 'vue-feather-icons';

@Component({
  components: {
    LockIcon
  }
})
export default class ARouterLinkSecurity extends Vue {
  @Prop(String)
  username!: string;
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_SETTINGS_SECURITY;
}
