import HttpService from '@/services/HttpService';

import { Role } from './interfaces/Role';
import { Roles } from './interfaces/Roles';
import { RolesNameDeletePath } from './interfaces/RolesNameDeletePath';
import { RolesNameGetPath } from './interfaces/RolesNameGetPath';
import { RolesNamePut } from './interfaces/RolesNamePut';
import { RolesNamePutPath } from './interfaces/RolesNamePutPath';
import { RolesPost } from './interfaces/RolesPost';

export default class RolesService {
  public static basePath = '/__/ms-roles-permissions/';

  /**
   *  Operation ID: rolesGet
   *  Summary: List all roles
   *  Description: List all roles in the system
   */
  public static rolesGet(): Promise<Roles> {
    return HttpService.sendRequest({
      method: 'GET',
      path: RolesService.basePath + 'roles',
    });
  }

  /**
   *  Operation ID: rolesPost
   *  Summary: Create a new role
   *  Description: By default all permissions will for the role will be off
   */
  public static rolesPost(body: RolesPost): Promise<Role> {
    return HttpService.sendRequest({
      method: 'POST',
      path: RolesService.basePath + 'roles',
      body,
    });
  }

  /**
   *  Operation ID: rolesNameDelete
   *  Summary: Delete a role
   *  Description:
   */
  public static rolesNameDelete(
    pathParams: RolesNameDeletePath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: RolesService.basePath + 'roles/:name',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: rolesNameGet
   *  Summary: List all roles
   *  Description: List all roles in the system
   */
  public static rolesNameGet(pathParams: RolesNameGetPath): Promise<Role> {
    return HttpService.sendRequest({
      method: 'GET',
      path: RolesService.basePath + 'roles/:name',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: rolesNamePut
   *  Summary: Patch
   *  Description: List all roles in the system
   */
  public static rolesNamePut(
    body: RolesNamePut,
    pathParams: RolesNamePutPath
  ): Promise<Role> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: RolesService.basePath + 'roles/:name',
      body,
      params: pathParams,
    });
  }
}
