










import { Component, Prop, Vue } from 'vue-property-decorator';
import { XIcon } from 'vue-feather-icons';

@Component({
  components: { XIcon },
})
export default class ACloseModalButton extends Vue {
  @Prop({ default: '1.5x' })
  iconSize!: string;
}
