import Vue from 'vue';
import Router from 'vue-router';
import { RouteNames } from '@/router/RouteNames';
import TLogin from '@/components/templates/TLogin.vue';
import TMainLayoutAuthenticated from '@/components/templates/TMainLayoutAuthenticated.vue';
import TCreateAccount from '@/components/templates/TCreateAccount.vue';
import TMainLayoutNonAuthenticated from '@/components/templates/TMainLayoutNonAuthenticated.vue';

Vue.use(Router);

export default [
  {
    path: '/',
    component: TLogin,
    meta: {
      onlyAnon: true,
      showLiveChat: true,
    },
    name: RouteNames.ROUTE_INDEX
  },
  {
    path: '/login',
    component: TLogin,
    meta: {
      onlyAnon: true,
      showLiveChat: true,
    },
    name: RouteNames.ROUTE_LOGIN
  },
  {
    path: '/create-account',
    component: TCreateAccount,
    meta: {
      auth: true,
      allowNoRole: true,
      showLiveChat: true,
    },
    name: RouteNames.ROUTE_CREATE_ACCOUNT
  },
  // These routes must match the main user app for email linking
  {
    path: '/_',
    component: TMainLayoutNonAuthenticated,
    meta: {
      showLiveChat: true,
    },
    children: [{
      name: RouteNames.ROUTE_ACTIVATE_ACCOUNT,
      path: 'activate-account/:hash/:email',
      component: () => import(/* webpackChunkName: "TActivateAccount" */ '@/components/templates/TActivateAccount.vue'),
    }, {
      name: RouteNames.ROUTE_PASSWORD_RESET,
      path: 'password-reset/:hash/:email',
      component: () => import(/* webpackChunkName: "TPasswordReset" */ '@/components/templates/TPasswordReset.vue'),
    }],
  },
  {
    path: '/dashboard',
    component: TMainLayoutAuthenticated,
    meta: { auth: true },
    children: [
      {
        path: '',
        meta: {
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TDashboard" */ '@/components/templates/TDashboard.vue'),
        name: RouteNames.ROUTE_DASHBOARD,
      },
      // ROUTE_ACCOUNT
      {
        path: '/accounts',
        meta: {
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TAccounts" */ '@/components/templates/TAccounts.vue'),
        name: RouteNames.ROUTE_ACCOUNT,
      },
      {
        path: '/new-domain',
        meta: {
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TNewDomain" */ '@/components/templates/TNewDomain.vue'),
        name: RouteNames.ROUTE_NEW_DOMAIN,
      },
      {
        path: '/verify-install',
        meta: {
          forDomain: true,
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TDomainVerifyInstall" */ '@/components/templates/TDomainVerifyInstall.vue'),
        name: RouteNames.ROUTE_VALIDATE_INSTALL,
      },
      {
        path: '/domain-settings',
        meta: {
          forDomain: true,
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TDomainSettings" */ '@/components/templates/TDomainSettings.vue'),
        name: RouteNames.ROUTE_CONFIG,
      },
      {
        path: '/sidebar-settings',
        meta: {
          forDomain: true,
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TSidebarSettings" */ '@/components/templates/TSidebarSettings.vue'),
        name: RouteNames.ROUTE_SIDEBAR,
      },
      {
        path: '/add-item-form',
        meta: {
          forDomain: true,
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TSidebarSettingsAddItem" */ '@/components/templates/TSidebarSettingsAddItem.vue'),
        name: RouteNames.ROUTE_ADD_ITEM_RESTRICTION,
      },
      {
        path: '/add-item-locations',
        meta: {
          forDomain: true,
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TAddItemLocations" */ '@/components/templates/TAddItemLocations.vue'),
        name: RouteNames.ROUTE_ADD_ITEM_CONTROLS,
      },
      {
        path: '/sidebar-styles',
        meta: {
          forDomain: true,
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TSidebarStyle" */ '@/components/templates/TSidebarStyle.vue'),
        name: RouteNames.ROUTE_SIDEBAR_STYLE,
      },
      {
        path: '/statistics',
        meta: {
          forDomain: true,
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TStatistics" */ '@/components/templates/TStatistics.vue'),
        name: RouteNames.ROUTE_STATISTICS,
      },
      {
        path: '/your-competitors',
        meta: {
          forDomain: true,
          showLiveChat: true,
        },
        component: () => import(/* webpackChunkName: "TYourCompetitors" */ '@/components/templates/TYourCompetitors.vue'),
        name: RouteNames.ROUTE_YOUR_COMPETITORS,
      },
      {
        path: '/comments',
        meta: {
          forDomain: true
        },
        component: () => import(/* webpackChunkName: "TComments" */ '@/components/templates/TComments.vue'),
        name: RouteNames.ROUTE_COMMENTS,
      },
      {
        path: '/comments-direct',
        meta: {
          forDomain: true
        },
        component: () => import(/* webpackChunkName: "TCommentsDirect" */ '@/components/templates/TCommentsDirect.vue'),
        name: RouteNames.ROUTE_DIRECT_COMMENTS,
      },
      {
        path: '/customer-messaging',
        meta: {
          forDomain: true,
          showLiveChat: true
        },
        component: () => import(/* webpackChunkName: "TB2CMessaging" */ '@/components/templates/TB2CMessaging.vue'),
        name: RouteNames.ROUTE_B2C_MESSAGING,
      },
      {
        name: RouteNames.ROUTE_SETTINGS,
        path: '/profile/:username/settings',
        meta: {
          ownOnly: true,
          allowNoRole: true
        },
        component: () => import(/* webpackChunkName: "TUserSettings" */ '@/components/templates/TUserSettings.vue'),
        children: [
          {
            name: RouteNames.ROUTE_SETTINGS_GENERAL,
            path: '',
            component: () => import(/* webpackChunkName: "OProfileSettingsGeneralForm" */ '@/components/organisms/forms/OProfileSettingsGeneralForm.vue'),
          },
          {
            name: RouteNames.ROUTE_SETTINGS_SECURITY,
            path: 'security',
            component: () => import(/* webpackChunkName: "OProfileSettingsEmailForm" */ '@/components/organisms/forms/OProfileSettingsEmailForm.vue'),
          },
        ],
      }
    ]
  },
  {
    // catch all login
    path: '*',
    component: TMainLayoutAuthenticated,
    children: [{
      name: RouteNames.ROUTE_LOST,
      path: '',
      component: () => import(/* webpackChunkName: "T404" */ '@/components/templates/T404.vue'),
    }],
  }
];
