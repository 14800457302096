import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';
import { getModule } from 'vuex-module-decorators';
import EventBus, { EventBusEvents } from '@/EventBus';
import { StoreNames } from '@/store/modules/enums/StoreNames';

import AuthenticationModule from '@/store/modules/AuthenticationModule';
import { IAuthenticationModule } from '@/store/modules/interfaces/AuthenticationModule';
import NotificationsModule from '@/store/modules/NotificationsModule';
import { INotificationsModule } from '@/store/modules/interfaces/NotificationsModule';
import RolesPermissionsModule from '@/store/modules/RolesPermissionsModule';
import { IRolesPermissionsModule } from '@/store/modules/interfaces/RolesPermissionsModule';
import BusinessAccountModule from '@/store/modules/BusinessAccountModule';
import { IBusinessAccountModule } from '@/store/modules/interfaces/BusinessAccountModule';
import NonPersistedStateModule from '@/store/modules/NonPersistedStateModule';
import { INonPersistedStateModule } from '@/store/modules/interfaces/NonPersistedStateModule';

Vue.use(Vuex);

// Declare the initial state interface
export interface IRootState {
  version: string;
  [StoreNames.AUTHENTICATION_STORE]: IAuthenticationModule;
  [StoreNames.NOTIFICATIONS_STORE]: INotificationsModule;
  [StoreNames.ROLES_PERMISSIONS_STORE]: IRolesPermissionsModule;
  [StoreNames.BUSINESS_ACCOUNT_STORE]: IBusinessAccountModule;
  [StoreNames.NON_PERSISTED_STATE_STORE]: INonPersistedStateModule;
}

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  reducer: (state: IRootState) => {
    // Only return items to save to the state
    return {
      version: state.version,
      [StoreNames.AUTHENTICATION_STORE]: state[StoreNames.AUTHENTICATION_STORE],
      [StoreNames.NOTIFICATIONS_STORE]: state[StoreNames.NOTIFICATIONS_STORE],
      [StoreNames.ROLES_PERMISSIONS_STORE]: state[StoreNames.ROLES_PERMISSIONS_STORE],
      [StoreNames.BUSINESS_ACCOUNT_STORE]: state[StoreNames.BUSINESS_ACCOUNT_STORE],
    };
  },
});

// Create the store
const store = new Vuex.Store<IRootState>({
  modules: {
    [StoreNames.AUTHENTICATION_STORE]: AuthenticationModule,
    [StoreNames.NOTIFICATIONS_STORE]: NotificationsModule,
    [StoreNames.ROLES_PERMISSIONS_STORE]: RolesPermissionsModule,
    [StoreNames.BUSINESS_ACCOUNT_STORE]: BusinessAccountModule,
    [StoreNames.NON_PERSISTED_STATE_STORE]: NonPersistedStateModule,
  },
  plugins: [vuexLocal.plugin],
});

// Export the modules for external access
export const AuthenticationStore = getModule(AuthenticationModule, store);
export const NotificationsStore = getModule(NotificationsModule, store);
export const RolesPermissionsStore = getModule(RolesPermissionsModule, store);
export const BusinessAccountStore = getModule(BusinessAccountModule, store);
export const NonPersistedStateStore = getModule(NonPersistedStateModule, store);

// Register the reset methods here - ie when someone logs out - nuke the stores
export const clearAll = () => {
  AuthenticationStore.RESET();
  NotificationsStore.RESET();
  RolesPermissionsStore.RESET();
  BusinessAccountStore.RESET();
  NonPersistedStateStore.RESET();
};
EventBus.$on(EventBusEvents.EV_LOGOUT, 'store/index', clearAll);

// last but not least, export the store
export default store;
