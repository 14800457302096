import EventBus, { EventBusEvents } from '@/EventBus';

export default class InternetStatus {
  static isOnline = true;
  static initialised = false;

  static setup (): void {
    if (!InternetStatus.initialised) {
      InternetStatus.initialised = true;
      InternetStatus.eventsBind();
      InternetStatus.setIsOnline(navigator.onLine);
    }
  }

  static setIsOnline (online: boolean): void {
    EventBus.$emit(EventBusEvents.NETWORK_STATUS_CHANGE, online);
    InternetStatus.isOnline = online;
  }

  static eventsBind (): void {
    window.addEventListener('offline', () => InternetStatus.setIsOnline(false));
    window.addEventListener('online', () => InternetStatus.setIsOnline(true));
  }
}
