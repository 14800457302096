


































































































import { Component, Vue } from 'vue-property-decorator';
import { RegisterEmailPost } from '@/api/ms-authentication/services/interfaces';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { AuthenticationStore } from '@/store';

import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import APasswordInputWithValidation from '@/storybook-components/src/stories/atoms/APasswordInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import MSsoButtons from '@/components/molecules/MSSOButtons.vue';
import MAgreeToTcsCs from '@/components/molecules/MAgreeToTcsCs.vue';

@Component({
  components: {
    MAgreeToTcsCs,
    AFormServerError,
    AButtonSubmit,
    MInputWithValidation,
    ValidationObserver,
    ValidationProvider,
    APasswordInputWithValidation,
    MSsoButtons
  },
})
export default class ORegisterForm extends Vue {
  serverError: boolean = false;
  loading: boolean = false;
  termsAgreed = false;
  form: RegisterEmailPost = {
    allTermsAgreed: new Date(),
    email: '',
    password: '',
    passwordConfirm: '',
    firstName: '',
    lastName: '',
    registeredFromHref: window.location.href
  };

  get errors () {
    return AuthenticationStore.errorRegisterContent;
  }

  created () {
    if (this.$route.query.inviteCode) {
      this.form.inviteCode = this.$route.query.inviteCode as string;
    }
  }

  agreedToTerms () {
    this.termsAgreed = true;
  }

  disagreedToTerms () {
    this.termsAgreed = false;
  }

  async onSubmit () {
    this.serverError = false;
    this.loading = true;
    try {
      this.form.firstName = this.form.firstName.trim();
      this.form.lastName = this.form.lastName.trim();
      this.form.email = this.form.email.trim();
      await AuthenticationStore.register(this.form);
    } catch (e: any) {
      this.serverError = true;
    }
    this.loading = false;
  }

  handleMessageClosed () {
    AuthenticationStore.ERRORS_REGISTER_UNSET();
  }
}
