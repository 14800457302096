import HttpService from '@/services/HttpService';

import { Connection } from './interfaces/Connection';
import { GenericMessage } from './interfaces/GenericMessage';
import { Invitation } from './interfaces/Invitation';
import { InvitationGet } from './interfaces/InvitationGet';
import { Invitations } from './interfaces/Invitations';
import { User } from './interfaces/User';
import { UserEmailPut } from './interfaces/UserEmailPut';
import { UserInvitationsAssignIdResendGetPath } from './interfaces/UserInvitationsAssignIdResendGetPath';
import { UserInvitationsAssignPost } from './interfaces/UserInvitationsAssignPost';
import { UserInvitationsInviteeCodeGetPath } from './interfaces/UserInvitationsInviteeCodeGetPath';
import { UserMeta } from './interfaces/UserMeta';
import { UserPasswordPut } from './interfaces/UserPasswordPut';
import { UserProfilePicturePutFormData } from './interfaces/UserProfilePicturePutFormData';
import { UserPut } from './interfaces/UserPut';
import { UserSearchConnectionsGetQuery } from './interfaces/UserSearchConnectionsGetQuery';
import { UserSearchConnectionss } from './interfaces/UserSearchConnectionss';
import { UserSearchGetQuery } from './interfaces/UserSearchGetQuery';
import { UserSearchs } from './interfaces/UserSearchs';
import { UserUsernameGetPath } from './interfaces/UserUsernameGetPath';
import { UserUsernameIsConnectedToCurrentUserGetPath } from './interfaces/UserUsernameIsConnectedToCurrentUserGetPath';

export default class UserService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: userDelete
   *  Summary: Delete a user
   *  Description: Sends an email to the admin which will in turn result in the users account being delete after a confirmation email
   */
  public static userDelete(): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: UserService.basePath + 'user',
    });
  }

  /**
   *  Operation ID: userGet
   *  Summary: Get user
   *  Description:
   */
  public static userGet(): Promise<User> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user',
    });
  }

  /**
   *  Operation ID: userPut
   *  Summary: Update a user
   *  Description: Update non-critical data
   */
  public static userPut(body: UserPut): Promise<User> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: UserService.basePath + 'user',
      body,
    });
  }

  /**
   *  Operation ID: userEmailPut
   *  Summary: Update a email, from user
   *  Description: Updates the users primary email
   */
  public static userEmailPut(body: UserEmailPut): Promise<User> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: UserService.basePath + 'user/email',
      body,
    });
  }

  /**
   *  Operation ID: userInvitationsAssignPost
   *  Summary: Create a assign, from invitations, from user
   *  Description:
   */
  public static userInvitationsAssignPost(
    body: UserInvitationsAssignPost
  ): Promise<Invitation> {
    return HttpService.sendRequest({
      method: 'POST',
      path: UserService.basePath + 'user/invitations/assign',
      body,
    });
  }

  /**
   *  Operation ID: userInvitationsAssignIdResendGet
   *  Summary: Get resend, from assign {id}, from invitations, from user
   *  Description:
   */
  public static userInvitationsAssignIdResendGet(
    pathParams: UserInvitationsAssignIdResendGetPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/invitations/assign/:id/resend',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: userInvitationsGet
   *  Summary: Get invitations, from user
   *  Description:
   */
  public static userInvitationsGet(): Promise<Invitations> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/invitations',
    });
  }

  /**
   *  Operation ID: userInvitationsInviteeCodeGet
   *  Summary: Get invitee based on {code}, from invitations, from user
   *  Description:
   */
  public static userInvitationsInviteeCodeGet(
    pathParams: UserInvitationsInviteeCodeGetPath
  ): Promise<InvitationGet> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/invitations/invitee/:code',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: userInvitationsMultiGet
   *  Summary: Get multi, from invitations, from user
   *  Description:
   */
  public static userInvitationsMultiGet(): Promise<Invitation> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/invitations/multi',
    });
  }

  /**
   *  Operation ID: userPasswordPut
   *  Summary: Update a password, from user
   *  Description: Updates the current users registered password
   */
  public static userPasswordPut(
    body: UserPasswordPut
  ): Promise<GenericMessage> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: UserService.basePath + 'user/password',
      body,
    });
  }

  /**
   *  Operation ID: userProfilePicturePut
   *  Summary: Update a profile-picture, from user
   *  Description: Uploads a picture to a a user
   */
  public static userProfilePicturePut(
    formData: UserProfilePicturePutFormData
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: UserService.basePath + 'user/profile-picture',
      formData,
    });
  }

  /**
   *  Operation ID: userSearchConnectionsGet
   *  Summary: Get connections, from search, from user
   *  Description:
   */
  public static userSearchConnectionsGet(
    query: UserSearchConnectionsGetQuery
  ): Promise<UserSearchConnectionss> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/search/connections',
      qs: query,
    });
  }

  /**
   *  Operation ID: userSearchGet
   *  Summary: Get search, from user
   *  Description:
   */
  public static userSearchGet(query: UserSearchGetQuery): Promise<UserSearchs> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/search',
      qs: query,
    });
  }

  /**
   *  Operation ID: userUsernameGet
   *  Summary: Get user based on {username}
   *  Description: Fetches only the base meta data for a profile page
   */
  public static userUsernameGet(
    pathParams: UserUsernameGetPath
  ): Promise<UserMeta> {
    return HttpService.sendRequest({
      method: 'GET',
      path: UserService.basePath + 'user/:username',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: userUsernameIsConnectedToCurrentUserGet
   *  Summary: Get is-connected-to-current-user, from user {username}
   *  Description: Fetches only the base meta data for a profile page
   */
  public static userUsernameIsConnectedToCurrentUserGet(
    pathParams: UserUsernameIsConnectedToCurrentUserGetPath
  ): Promise<Connection> {
    return HttpService.sendRequest({
      method: 'GET',
      path:
        UserService.basePath + 'user/:username/is-connected-to-current-user',
      params: pathParams,
    });
  }
}
