import axios, { AxiosError, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import config from '@/config';

export interface RequestObject {
  method: string
  path: string
  formData?: object
  params?: object
  qs?: object
  body?: object
  headers?: object
  baseUrl?: string
}

export default class HttpService {
  static headers: any = {};

  /**
   * Make the http request via axios
   */
  static sendRequest (requestObject: RequestObject): Promise<any> {
    return new Promise((resolve, reject) => {
      const baseUrl = requestObject.baseUrl || config.api.baseUrl;
      const URL = baseUrl + HttpService.injectParamsToPath(requestObject.params, requestObject.path);
      const axiosReq: AxiosRequestConfig = {
        headers: {
          ...requestObject.headers || {},
          ...HttpService.headers
        },
        method: requestObject.method as unknown as Method,
        data: requestObject.body || {},
        params: requestObject.qs || {},
        url: URL,
      };
      if (requestObject.formData) {
        axiosReq.headers = {
          ...axiosReq.headers,
          ...{ 'Content-Type': 'multipart/form-data' }
        };
        const formData = new FormData();
        for (const key in requestObject.formData) {
          formData.append(key, requestObject.formData[key]);
        }
        axiosReq.data = formData;
      }
      axios(axiosReq).then((response: AxiosResponse) => {
        resolve(response.data);
      }).catch((err: AxiosError) => {
        reject(err);
      });
    });
  }

  /**
   * Injects the values into a path
   */
  static injectParamsToPath (params: object = {}, path: string) {
    Object.keys(params).forEach((param) => {
      path = path.replace(':' + param, params[param]);
    });
    return path;
  }
}
