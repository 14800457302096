import { DomainPost } from '@/api/ms-business-account-mgmt/services/interfaces';
import DomainService from '@/api/ms-business-account-mgmt/services/DomainService';

export default class DefaultEmptyObjects {
  static fetched = false;
  static defaultNewDomain: DomainPost;

  static async setup () {
    DefaultEmptyObjects.defaultNewDomain = await DomainService.domainSkeletonGet();
    DefaultEmptyObjects.fetched = true;
  }
}

