







import { Component, Vue } from 'vue-property-decorator';
import AuthenticationService from '@/services/AuthenticationService';
import { LogOutIcon } from 'vue-feather-icons';

@Component({
  components: {
    LogOutIcon
  },
})
export default class ARouterLinkLogout extends Vue {
  async logout () {
    await AuthenticationService.logout();
  }
}
