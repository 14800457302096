import HttpService from '@/services/HttpService';

import { Notification } from './interfaces/Notification';
import { NotificationGetQuery } from './interfaces/NotificationGetQuery';
import { NotificationIdIdGetPath } from './interfaces/NotificationIdIdGetPath';
import { NotificationPatch } from './interfaces/NotificationPatch';
import { Notifications } from './interfaces/Notifications';

export default class NotificationService {
  public static basePath = '/__/ms-notification/';

  /**
   *  Operation ID: notificationGet
   *  Summary: Get notification
   *  Description:
   */
  public static notificationGet(
    query: NotificationGetQuery
  ): Promise<Notifications> {
    return HttpService.sendRequest({
      method: 'GET',
      path: NotificationService.basePath + 'notification',
      qs: query,
    });
  }

  /**
   *  Operation ID: notificationPatch
   *  Summary: Update part of a notification
   *  Description:
   */
  public static notificationPatch(
    body: NotificationPatch
  ): Promise<Notifications> {
    return HttpService.sendRequest({
      method: 'PATCH',
      path: NotificationService.basePath + 'notification',
      body,
    });
  }

  /**
   *  Operation ID: notificationIdIdGet
   *  Summary: Get id based on {id}, from notification
   *  Description:
   */
  public static notificationIdIdGet(
    pathParams: NotificationIdIdGetPath
  ): Promise<Notification> {
    return HttpService.sendRequest({
      method: 'GET',
      path: NotificationService.basePath + 'notification/id/:id',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: notificationMarkAllAsSeenPut
   *  Summary: Update a mark-all-as-seen, from notification
   *  Description: Marks all notifications as seen (actual state "interacted") for a user so they don't have to individually click each notification, uses JWT only
   */
  public static notificationMarkAllAsSeenPut(): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: NotificationService.basePath + 'notification/mark-all-as-seen',
    });
  }
}
