import HttpService from '@/services/HttpService';
import objectReduceByMap from 'object-reduce-by-map';

import { Account } from './interfaces/Account';
import { AccountGetQuery } from './interfaces/AccountGetQuery';
import { AccountIdDeletePath } from './interfaces/AccountIdDeletePath';
import { AccountIdDeleteQuery } from './interfaces/AccountIdDeleteQuery';
import { AccountIdGetPath } from './interfaces/AccountIdGetPath';
import { AccountIdGetQuery } from './interfaces/AccountIdGetQuery';
import { AccountIdPut } from './interfaces/AccountIdPut';
import { AccountIdPutPath } from './interfaces/AccountIdPutPath';
import { AccountIdPutQuery } from './interfaces/AccountIdPutQuery';
import { AccountPost } from './interfaces/AccountPost';
import { Accounts } from './interfaces/Accounts';

export default class AccountService {
  public static basePath = '/__/ms-business-account-mgmt/';

  /**
   *  Operation ID: accountGet
   *  Summary: Current user accounts related
   *  Description: This pulls either account the user can manage or owns
   */
  public static accountGet(query: AccountGetQuery): Promise<Accounts> {
    return HttpService.sendRequest({
      method: 'GET',
      path: AccountService.basePath + 'account',
      qs: query,
    });
  }

  /**
   *  Operation ID: accountPost
   *  Summary: Create a business account
   *  Description: Creates a new business account
   */
  public static accountPost(body: AccountPost): Promise<Account> {
    // definitions.AccountPost
    const mapbody = {
      name: String,
      membership: [
        {
          dateFrom: String,
          dateTo: String,
          type: String,
          level: Number,
          createdBy: String,
        },
      ],
      mainAddress: {
        unitNumber: String,
        streetNumberHouseName: String,
        addressLine1: String,
        addressLine2: String,
        addressLine3: String,
        stateProvinceRegion: String,
        zipPostalCode: String,
        country: String,
      },
      owner: String,
    };

    return HttpService.sendRequest({
      method: 'POST',
      path: AccountService.basePath + 'account',
      body: objectReduceByMap(body, mapbody),
    });
  }

  /**
   *  Operation ID: accountIdDelete
   *  Summary: Delete a role
   *  Description:
   */
  public static accountIdDelete(
    pathParams: AccountIdDeletePath,
    query: AccountIdDeleteQuery
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'DELETE',
      path: AccountService.basePath + 'account/:id',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: accountIdGet
   *  Summary: List all roles
   *  Description: List all roles in the system
   */
  public static accountIdGet(
    pathParams: AccountIdGetPath,
    query: AccountIdGetQuery
  ): Promise<Account> {
    return HttpService.sendRequest({
      method: 'GET',
      path: AccountService.basePath + 'account/:id',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: accountIdPut
   *  Summary: Update account information
   *  Description: Update account information
   */
  public static accountIdPut(
    body: AccountIdPut,
    pathParams: AccountIdPutPath,
    query: AccountIdPutQuery
  ): Promise<Account> {
    // definitions.AccountPut
    const mapbody = {
      name: String,
      membership: [
        {
          dateFrom: String,
          dateTo: String,
          type: String,
          level: Number,
          createdBy: String,
        },
      ],
      mainAddress: {
        unitNumber: String,
        streetNumberHouseName: String,
        addressLine1: String,
        addressLine2: String,
        addressLine3: String,
        stateProvinceRegion: String,
        zipPostalCode: String,
        country: String,
      },
      owner: String,
    };

    return HttpService.sendRequest({
      method: 'PUT',
      path: AccountService.basePath + 'account/:id',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
      qs: query,
    });
  }
}
