class NewLineConverter {
  brTag = '<br>';

  nl2br (str: string): string {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + this.brTag + '$2');
  }

  br2nl (str) {
    // Includes <br>, <BR>, <br />, </br>
    return str.replace(/<\s*\/?br\s*[/]?>/gi, '');
  }

  toArray (input: string) {
    return input.trim().split(/\s*[\r\n]+\s*/g).filter(item => item !== '');
  }

  toNl (input: string[]) {
    return input.join('\n');
  }
}

export default new NewLineConverter();
