














import { Component, Prop, Vue } from 'vue-property-decorator';
import MLanguageSwitcher from '@/components/molecules/MLanguageSwitcher.vue';
import OModalWrapper from '@/storybook-components/src/stories/organisms/OModalWrapper.vue';

@Component({
  components: { OModalWrapper, MLanguageSwitcher }
})
export default class OMenuSidebar extends Vue {
  @Prop({ required: true })
  open!: boolean;
}
