/**
 * Returns a Date object plus x days
 * @param days
 * @return {Date}
 */
export default (days = 0) => {
  const date = new Date();
  date.setDate(date.getDate() + days);
  return date;
};
