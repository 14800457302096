import EventBus, { EventBusEvents } from '@/EventBus';

export default (bePolite = false) => {
  navigator.serviceWorker.getRegistrations()
    .then((regs) => {
      if (!Array.isArray(regs)) {
        return;
      }
      return Promise.all(
        regs.map(reg => reg.unregister())
      );
    })
    .then(() => {
      if (document.hidden) {
        window.location.reload();
      } else if (bePolite) {
        EventBus.$emit(EventBusEvents.EV_NEW_APP_DOWNLOADED);
      } else {
        window.location.reload();
      }
    })
    .catch((err) => {
      console.error('Service worker unregister error:', err);
      throw err;
    });
};
