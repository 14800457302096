import HttpService from '@/services/HttpService';

import { Jwt } from './interfaces/Jwt';
import { JwtRenewAccessOrRenewalPost } from './interfaces/JwtRenewAccessOrRenewalPost';
import { Login } from './interfaces/Login';

export default class TokenService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: tokenRenewAccessPost
   *  Summary: Create a access, from renew, from token
   *  Description: Returns a new access-or-renewal JWT based on a valid renewal token provided.
   */
  public static tokenRenewAccessPost(
    body: JwtRenewAccessOrRenewalPost
  ): Promise<Jwt> {
    return HttpService.sendRequest({
      method: 'POST',
      path: TokenService.basePath + 'token/renew/access',
      body,
    });
  }

  /**
   *  Operation ID: tokenRenewRenewalPost
   *  Summary: Create a renewal, from renew, from token
   *  Description: Returns a new renewal JWT based on a valid renewal and access token provided.
   */
  public static tokenRenewRenewalPost(
    body: JwtRenewAccessOrRenewalPost
  ): Promise<Login> {
    return HttpService.sendRequest({
      method: 'POST',
      path: TokenService.basePath + 'token/renew/renewal',
      body,
    });
  }
}
