import HttpService from '@/services/HttpService';

import { Login } from './interfaces/Login';
import { LoginEmailPost } from './interfaces/LoginEmailPost';
import { LoginFacebookPost } from './interfaces/LoginFacebookPost';
import { LoginGooglePost } from './interfaces/LoginGooglePost';

export default class LoginService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: loginEmailPost
   *  Summary: Create a email, from login
   *  Description: Log a user into the system
   */
  public static loginEmailPost(body: LoginEmailPost): Promise<Login> {
    return HttpService.sendRequest({
      method: 'POST',
      path: LoginService.basePath + 'login/email',
      body,
    });
  }

  /**
   *  Operation ID: loginFacebookPost
   *  Summary: Create a facebook, from login
   *  Description: Log a user into the system via facebook or create their account and then login
   */
  public static loginFacebookPost(body: LoginFacebookPost): Promise<Login> {
    return HttpService.sendRequest({
      method: 'POST',
      path: LoginService.basePath + 'login/facebook',
      body,
    });
  }

  /**
   *  Operation ID: loginGooglePost
   *  Summary: Create a google, from login
   *  Description: Log a user into the system via facebook or create their account and then login
   */
  public static loginGooglePost(body: LoginGooglePost): Promise<Login> {
    return HttpService.sendRequest({
      method: 'POST',
      path: LoginService.basePath + 'login/google',
      body,
    });
  }
}
