/**
 * A very simple naming convention.
 * EV stands for event following by the domain it relates.
 * General rule of thumb that the event bus is not expected
 * to send data in the payload. It is merely is notification
 * of an event occurring.
 */
export enum EventBusEvents {
  EV_NEW_APP_DOWNLOADED = 'EV_NEW_APP_DOWNLOADED',
  EV_LOGOUT = 'EV_LOGOUT',
  EV_LOGIN = 'EV_LOGIN',
  EV_AUTH_CHANGE = 'EV_AUTH_CHANGE',
  EV_ACCOUNT_CHANGE = 'EV_ACCOUNT_CHANGE',
  NETWORK_STATUS_CHANGE = 'NETWORK_STATUS_CHANGE',
}

export interface BusEvent {
  name: string,
  callerId: string,
  callback: (event: any) => void
}

class EventBus {
  static verbose = false;
  static events: BusEvent[] = [];

  /**
   * Runs the function assigned to each event name
   * @param name
   * @param payload
   */
  static $emit (name: EventBusEvents, payload?: any) {
    EventBus.events.forEach((event: BusEvent) => {
      if (event.name === name) {
        event.callback(payload);
        if (EventBus.verbose) {
          console.info(`Eventbus.$emit event name: ${name}, callerId: ${event.callerId}`);
        }
      }
    });
  }

  /**
   * Listen to an event, cb run when event called
   * @param name - Name of the event
   * @param callerId - Which component added the event, prevents the same component adding the same event multiple times
   * @param callback - What to do when the event is called with $emit
   */
  static $on (name: EventBusEvents, callerId: string, callback: (event: any) => void): void {
    const foundIndex = EventBus.events.findIndex((event: BusEvent) => {
      return event.name === name && event.callerId === callerId;
    });
    if (foundIndex !== -1) {
      // remove the event to prevent events stacking up
      EventBus.events.splice(foundIndex, 1);
    }
    this.events.push({ name, callerId, callback });
    if (EventBus.verbose) {
      console.info(`Eventbus.$on event name: ${name}, callerId: ${callerId}. New stack:`, EventBus.events);
    }
  }

  /**
   * Remove an event from the stack
   * @param name - The name of the event to remove
   * @param [callerId] - The component callerId, if not passed removes all events by the name provided
   */
  static $remove (name: EventBusEvents, callerId?: string) {
    EventBus.events = EventBus.events.filter((event: BusEvent) => {
      if (callerId) {
        return event.name !== name && event.callerId !== callerId;
      }
      return event.name !== name;
    });
    if (EventBus.verbose) {
      console.info(`Eventbus.$remove event name: ${name}, callerId: ${callerId}. New stack:`, EventBus.events);
    }
  }
}

export default EventBus;
