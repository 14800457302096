import * as rules from 'vee-validate/dist/rules';
import getFirstUrlFromString from 'get-first-url-from-string';

export default {
  ...rules,
  isUrl: (value) => {
    try {
      new URL(value);
      return true;
    } catch (e: any) {
      return 'That does not look like a valid URL';
    }
  },
  isUrlOrigin: (inputValue: string) => {
    const value = getFirstUrlFromString(inputValue);
    if (inputValue !== value) {
      return 'That does not look like a valid URL';
    }
    let url;
    try {
      url = new URL(value);
    } catch (e: any) {
      return 'That does not look like a valid URL';
    }

    if (url.protocol !== 'https:') {
      return 'The URL must start with https';
    }
    if (url.username !== '' || url.password !== '') {
      return 'You must not pass a username or password with the URL';
    }
    if (url.search !== '') {
      return `You must not pass a search value, found ${url.search}`;
    }
    if (url.hash !== '') {
      return `You must not pass a hash, found ${url.hash}`;
    }
    if (url.pathname.length > 1) {
      return `You must not pass a path, found ${url.pathname}`;
    }
    if (['/', '?', '#'].indexOf(value.charAt(value.length - 1)) !== -1) {
      return 'The value must not end with a /, # or ?';
    }
    return true;
  }
};
