import { register } from 'register-service-worker';
import serviceWorkerUnregisterAll from '@/utils/serviceWorkerUnregisterAll';

if (process.env.NODE_ENV === 'production') {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready () {
      console.log('Liffery is being served from cache by a service worker.',);
    },
    registered () {
      console.log('Service worker has been registered.');
    },
    cached () {
      console.log('Liffery content has been cached for offline use.');
    },
    updatefound () {
      console.log('A new Liffery app version has been found, downloading.');
    },
    updated () {
      console.log('New content is available; please refresh.');
      serviceWorkerUnregisterAll(true);
    },
    offline () {
      console.log('No internet connection found. App is running in offline mode.');
    },
    error (error) {
      console.error('Error during service worker registration:', error);
      throw error;
    },
  });
}
