














































import { Component, Prop, Vue } from 'vue-property-decorator';
import { translation } from '@/plugins/i18n/Translation';
import { ChevronDownIcon } from 'vue-feather-icons';
import setLanguage from '@/utils/setLanguage';

@Component({
  components: { ChevronDownIcon }
})
export default class MLanguageSwitcher extends Vue {
  @Prop()
  isSelect!: boolean;

  get currentLanguage () {
    return translation.currentLanguage;
  }

  get languages () {
    return translation.supportedLanguages;
  }

  async changeLanguage (language) {
    await setLanguage(language);
  }
}
