import { InjectType, ssoScriptInjector } from '@/utils/ssoScriptInjector';
import config from '@/config';

export const SSOFb = (): Promise<any> => {
  return new Promise((resolve, reject) => {
    const injectedId = 'liffery-facebook-single-sign-on-script';
    const errorHandler = (e) => {
      reject('Google login error');
      console.error(e);
    };
    // authenticate
    const auth = () => {
      window.FB.login(function (response) {
        if (response.authResponse) {
          resolve(response);
        } else {
          reject('Facebook login cancelled');
        }
      }, {
        scope: 'email'
      });
    };

    ssoScriptInjector(injectedId, InjectType.facebook).then(() => {
      // setup fb
      if (!window.fbAsyncInit) {
        window.fbAsyncInit = function () {
          window.FB.init({
            appId: config.sso.facebookAppId,
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v9.0'
          });
          auth();
        };
      } else {
        auth();
      }
    }).catch(errorHandler);
  });
};