import { Account, Domain } from '@/api/ms-business-account-mgmt/services/interfaces';
import {
  Fetch,
  OpenControlType,
  Order,
  Position,
  Sort,
  UrlType
} from '@/api/ms-business-account-mgmt/services/interfaces/Domain';

export const selectedAccountDummy: Account = {
  name: '',
  _id: '',
  createdAt: new Date(),
  createdBy: '',
  mainAddress: {
    addressLine1: '',
    country: '',
    streetNumberHouseName: '',
    zipPostalCode: ''
  },
  membership: [],
  owner: '',
  updatedAt: new Date()
};

export const selectedDomainDummy: Domain = {
  _id: '',
  origin: '',
  createdAt: new Date(),
  createdBy: '',
  accountId: '',
  active: false,
  updatedAt: new Date(),
  validated: false,
  sidebarSettings: {
    attentionSeekers: [],
    openType: {
      openControlType: OpenControlType.Tab,
      logoTargets: [{
        target: '',
        append: false,
        height: 50
      }]
    },
    addItemTargets: {},
    customCSS: '',
    featureSwitch: {},
    initialStateOpen: true,
    position: Position.Right,
    promoteUrls: [],
    promoteUrlsTitle: '',
    showFriendsItems: {
      show: true,
      position: 1,
      fetch: Fetch.All,
      noResultsText: '',
      order: Order.CreatedAt,
      sort: Sort.Asc
    },
    showOthersItems: {
      show: true,
      position: 2,
      fetch: Fetch.All,
      noResultsText: '',
      order: Order.CreatedAt,
      sort: Sort.Asc
    },
    showOwnItems: {
      show: true,
      position: 1,
      fetch: Fetch.All,
      noResultsText: '',
      order: Order.CreatedAt,
      sort: Sort.Asc
    },
    unauthenticated: {
      nonProductPageImage: {
        url: '',
        urlType: UrlType.Link
      }
    }
  }
};
