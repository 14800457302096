/**
 * Returns a Date object plus x months
 * @param {number} months
 * @return {Date}
 */
export default (months = 0) => {
  const date = new Date();
  date.setMonth(date.getMonth() + months);
  return date;
};
