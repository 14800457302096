import HttpService from '@/services/HttpService';

import { Permissions } from './interfaces/Permissions';

export default class PermissionService {
  public static basePath = '/__/ms-roles-permissions/';

  /**
   *  Operation ID: permissionGet
   *  Summary: List all permissions
   *  Description: List all permissions in a single list
   */
  public static permissionGet(): Promise<Permissions> {
    return HttpService.sendRequest({
      method: 'GET',
      path: PermissionService.basePath + 'permission',
    });
  }
}
