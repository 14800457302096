
































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import MAddressCard from '@/components/molecules/MAddressCard.vue';
import { MainAddress } from '@/api/ms-business-account-mgmt/services/interfaces/AccountPost';
import ATooltip from '@/storybook-components/src/stories/atoms/ATooltip.vue';

@Component({
  components: { ATooltip, MAddressCard }
})
export default class MGoogleAutoComplete extends Vue {
  @Prop({ required: true })
  value!: any;
  @Prop({ default: 'dict.search' })
  placeholder!: string;

  @Prop({ default: 'googleAddress.label' })
  label?: string;
  @Prop({ default: 'googleAddress.tooltip' })
  tooltip?: string;

  autocomplete!: any;
  $refs!: {
    autocomplete: any;
  };

  innerValue: MainAddress = {
    unitNumber: '',
    streetNumberHouseName: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
    country: '',
    stateProvinceRegion: '',
    zipPostalCode: ''
  };

  created () {
    if (this.value) {
      this.innerValue = this.value;
    }
  }

  mounted () {
    // eslint-disable-next-line no-undef
    this.autocomplete = new google.maps.places.Autocomplete(
      (this.$refs.autocomplete),
      { types: ['geocode'] }
    );
    this.autocomplete.addListener('place_changed', this.handlePlaceChange);
  }

  innerValueClear () {
    for (let key in this.innerValue) {
      this.innerValue[key] = '';
    }
  }

  handlePlaceChange () {
    let place = this.autocomplete.getPlace();
    this.innerValueClear();
    place.address_components.forEach((component: { long_name: string; short_name: string; types: string[]; }) => {
      component.types.forEach((type: string) => {
        const value = component.long_name;
        switch (type) {
          case 'street_number':
            this.innerValue.streetNumberHouseName = value;
            break;
          case 'route':
            this.innerValue.addressLine1 = value;
            break;
          case 'locality':
            this.innerValue.addressLine2 = value;
            break;
          case 'neighborhood':
            this.innerValue.addressLine3 = value;
            break;
          case 'postal_town':
            this.innerValue.addressLine3 = value;
            break;
          case 'administrative_area_level_2':
            this.innerValue.stateProvinceRegion = value;
            break;
          case 'postal_code':
            this.innerValue.zipPostalCode = value;
            break;
          case 'country':
            this.innerValue.country = value;
            break;
        }
      });
    });
  }

  @Watch('innerValue')
  innerValueHandle (newVal) {
    this.$emit('child-output', newVal);
    this.$emit('input', newVal);
  }

  @Watch('value')
  valueValueHandle (newVal) {
    this.innerValue = newVal;
  }
}
