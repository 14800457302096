import { IStoreModule } from '@/store/modules/interfaces/IStoreModule';
import { Generic422, User } from '@/api/ms-authentication/services/interfaces';
import { Language } from '@/api/ms-authentication/services/interfaces/User';

export interface IAuthenticationModule extends IStoreModule {
  loginCaptchaRequired: boolean;
  lastEmailLoginAttempt: string;
  authenticated: boolean;
  errorCaptcha: Generic422;
  errorRegister: Generic422;
  errorLogin: Generic422;
  prompt: {
    login: boolean,
    loginActiveTab: number
  }
  user: User
}

export const InitialGenericError: Generic422 = {};

export const InitialUserState: User = {
  email: '',
  username: '',
  firstName: '',
  lastName: '',
  language: Language.EnGB,
  birthday: undefined,
  captchaLocked: false,
  verified: false,
  measurements: {
    heightCM: 0,
    wasteCM: 0,
    shoeSizeEU: 0,
  }
};