import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import { IRolesPermissionsModule } from '@/store/modules/interfaces/RolesPermissionsModule';
import { Permissions, RolePost, RolePut, Roles } from '@/api/ms-roles-permissions/services/interfaces';
import PermissionService from '@/api/ms-roles-permissions/services/PermissionService';
import RolesService from '@/api/ms-roles-permissions/services/RolesService';

@Module({
  name: StoreNames.ROLES_PERMISSIONS_STORE,
  namespaced: true,
})
export default class RolesPermissionsModule extends VuexModule implements IRolesPermissionsModule {
  @Mutation
  RESET () {
    this.roles = [];
    this.permissions = [];
  }

  roles: Roles = [];
  permissions: Permissions = [];

  get getRoles () {
    return this.roles;
  }

  get getPermissions () {
    return this.permissions;
  }

  get getPermissionsFlatArray () {
    return this.permissions.map((permission) => {
      return permission.name
    });
  }

  @Action
  async createNewRole (input: RolePost) {
    await RolesService.rolesPost(input);
    await this.fetchRolesAndPermissions();
  }

  @Action
  async updateRole (input: RolePut) {
    await RolesService.rolesNamePut(input, {
      name: input.name
    });
    await this.fetchRolesAndPermissions();
  }

  @Action
  async deleteRole (roleName: string) {
    await RolesService.rolesNameDelete({
      name: roleName
    });
    await this.fetchRolesAndPermissions();
  }

  @Action
  async fetchRolesAndPermissions () {
    this.SET_PERMISSIONS(
      await PermissionService.permissionGet()
    );
    this.SET_ROLES(
      await RolesService.rolesGet()
    );
  }

  @Mutation
  SET_PERMISSIONS (permissions: Permissions) {
    this.permissions = permissions;
  }

  @Mutation
  SET_ROLES (roles: Roles) {
    this.roles = roles;
  }
}
