


























































import { Component, Vue } from 'vue-property-decorator';
import { AuthenticationStore, NonPersistedStateStore } from '@/store';
import MUserProfileHeader from '@/components/molecules/MUserProfileHeader.vue';
import MHeader from '@/storybook-components/src/stories/molecules/MHeader.vue';
import { RouteNames } from '@/router/RouteNames';
import ALifferyLogo from '@/components/atoms/ALifferyLogo.vue';

@Component({
  components: { ALifferyLogo, MHeader, MUserProfileHeader }
})
export default class ONavbar extends Vue {
  routes = RouteNames;

  get integrationMode (): boolean {
    return NonPersistedStateStore.getIntegrationModeOn;
  }

  get username () {
    return AuthenticationStore.getCurrentUser.username;
  }
}
