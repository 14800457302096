import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from '@/constantsAndEnums/translation';
import { i18n } from '@/plugins/i18n';

class Translation {
  get defaultLanguage() {
    return DEFAULT_LANGUAGE;
  }

  get supportedLanguages() {
    return SUPPORTED_LANGUAGES;
  }

  get currentLanguage() {
    return i18n.locale;
  }

  set currentLanguage(lang) {
    i18n.locale = lang;
  }

  /**
   * Loads new translation messages and changes the language when finished
   * @param lang
   * @return {Promise<any>}
   */
  async changeLanguage(lang: string) {
    if (i18n.locale !== lang && this.isLangSupported(lang)) {
      const messages = await this.loadLanguageFile(lang);
      i18n.setLocaleMessage(lang, messages.default);
      i18n.locale = lang;
    }
  }

  /**
   * Async loads a translation file
   * @param lang
   * @return {Promise<*>|*}
   */
  loadLanguageFile(lang) {
    return import(/* webpackChunkName: "lang-[request]" */ `@/translations/${lang}.json`);
  }

  /**
   * Checks if a lang is supported
   * @param {String} lang
   * @return {boolean}
   */
  isLangSupported(lang) {
    return this.supportedLanguages.includes(lang);
  }
}

export const translation = new Translation();
