import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import Auth from '@/services/AuthenticationService';
import {
  CaptchaPost,
  Generic422,
  JwtRenewAccessOrRenewalPost,
  Login,
  LoginEmailPost,
  LoginFacebookPost,
  LoginGooglePost,
  RegisterEmailPost,
  User,
  UserEmailPut,
  UserPasswordPut,
  UserPut
} from '@/api/ms-authentication/services/interfaces';
import { TAB_LOGIN, TAB_PASSWORD_RESET } from '@/constantsAndEnums/tabs';
import EventBus, { EventBusEvents } from '@/EventBus';
import LoginService from '@/api/ms-authentication/services/LoginService';
import CaptchaService from '@/api/ms-authentication/services/CaptchaService';
import LogoutService from '@/api/ms-authentication/services/LogoutService';
import TokenService from '@/api/ms-authentication/services/TokenService';
import RegisterService from '@/api/ms-authentication/services/RegisterService';
import UserService from '@/api/ms-authentication/services/UserService';
import WebSocket from '@/socket/WebSocket';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  IAuthenticationModule,
  InitialGenericError,
  InitialUserState
} from '@/store/modules/interfaces/AuthenticationModule';
import { Language } from '@/api/ms-authentication/services/interfaces/User';
import { AuthenticationStore } from '@/store';
import { SUPERADMIN } from '@/constantsAndEnums/permittedRoles';

@Module({
  name: StoreNames.AUTHENTICATION_STORE,
  namespaced: true,
})
export default class AuthenticationModule extends VuexModule implements IAuthenticationModule {
  public loginCaptchaRequired = false;
  public lastEmailLoginAttempt = '';
  public authenticated = false;
  public errorCaptcha = InitialGenericError;
  public errorRegister = InitialGenericError;
  public errorLogin = InitialGenericError;
  public prompt = {
    login: false,
    loginActiveTab: 0
  };
  public user: User = InitialUserState;

  get getAuthenticated () {
    return this.authenticated;
  }

  get isSuperAdmin () {
    return AuthenticationStore.getCurrentUser.roles?.includes(SUPERADMIN);
  }

  get getCurrentUser () {
    return this.user;
  }

  get errorCaptchaContent () {
    return this.errorCaptcha.errors;
  }

  get errorLoginContent () {
    return this.errorLogin.errors;
  }

  get errorRegisterContent () {
    return this.errorRegister.errors;
  }

  get captchaRequired () {
    return this.loginCaptchaRequired;
  }

  @Mutation
  RESET () {
    this.user = InitialUserState;
    this.loginCaptchaRequired = false;
    this.authenticated = false;
    this.errorCaptcha = {};
    this.errorRegister = {};
    this.errorLogin = {};
    this.prompt = {
      login: false,
      loginActiveTab: 0
    };
  }

  @Action
  public clearAuthErrors () {
    this.ERRORS_CLEAR();
    this.ERRORS_CAPTCHA_UNSET();
    this.ERRORS_REGISTER_UNSET();
    this.ERRORS_LOGIN_UNSET();
  }

  @Action
  public loginCallback (resp: Login) {
    Auth.injectAccessJWT(resp.tokenAccess.value);
    Auth.injectRenewalJWT(resp.tokenRenewal.value);
    this.USER_SET(resp.user);
    this.ERRORS_CLEAR();
    this.ERRORS_REGISTER_UNSET();
    EventBus.$emit(EventBusEvents.EV_LOGIN);
    EventBus.$emit(EventBusEvents.EV_AUTH_CHANGE);
  }

  @Action({ rawError: true })
  public async register (register: RegisterEmailPost) {
    try {
      const data = await RegisterService.registerEmailPost(register);
      this.loginCallback(data);
    } catch (e: any) {
      if (e.request.status === 422 || e.request.status === 406) {
        this.ERRORS_REGISTER_SET(e.response.data);
      }
      console.error(e);
    }
  }

  @Action({ rawError: true })
  public async login (data: LoginEmailPost) {
    try {
      const resp = await LoginService.loginEmailPost(data);
      this.loginCallback(resp);
    } catch (e: any) {
      if (e.request.status === 423) {
        this.CAPTCHA_SET_REQUIRED(data.email);
        this.ERRORS_LOGIN_UNSET();
      } else {
        this.ERRORS_LOGIN_SET();
      }
      console.error(e);
    }
  }

  @Action
  public async loginWithFacebook (data: LoginFacebookPost) {
    try {
      const resp = await LoginService.loginFacebookPost({
        ...data,
        registeredFromHref: window.location.href
      });
      this.loginCallback(resp);
    } catch (e: any) {
      console.error(e);
    }
  }

  @Action
  public async loginWithGoogle (data: LoginGooglePost) {
    try {
      const resp = await LoginService.loginGooglePost({
        ...data,
        registeredFromHref: window.location.href
      });
      this.loginCallback(resp);
    } catch (e: any) {
      console.error(e);
    }
  }

  @Action
  public async getCaptcha (email: string) {
    return CaptchaService.captchaGet({ email });
  }

  @Action
  public async unlockCaptcha (data: CaptchaPost) {
    try {
      await CaptchaService.captchaPost(data);
      this.ERRORS_CAPTCHA_UNSET();
      this.CAPTCHA_SET_NOT_REQUIRED();
      return true;
    } catch (e: any) {
      if (e.request.status === 422) {
        this.ERRORS_CAPTCHA_SET(e.response.data);
      }
      return false;
    }
  }

  @Action
  public async logout (bypassApiCall?: boolean) {
    const jwt = Auth.getRenewalJWT();
    if (typeof jwt === 'string' && !bypassApiCall) {
      await LogoutService.logoutGet();
    }
    Auth.ejectAccessJWT();
    Auth.ejectRenewalJWT();
    EventBus.$emit(EventBusEvents.EV_LOGOUT);
    EventBus.$emit(EventBusEvents.EV_AUTH_CHANGE);
    WebSocket.disconnect();
    this.USER_UNSET();
  }

  @Action
  public async renewAccessToken (renewalToken: JwtRenewAccessOrRenewalPost): Promise<string> {
    const data = await TokenService.tokenRenewAccessPost(renewalToken);
    Auth.injectAccessJWT(data.value);
    return data.value;
  }

  @Action({})
  public async renewRenewalToken (renewalToken: JwtRenewAccessOrRenewalPost) {
    const data = await TokenService.tokenRenewRenewalPost(renewalToken);
    Auth.injectRenewalJWT(data.tokenRenewal.value);
  }

  @Action
  public async updateProfileGeneral (data: UserPut) {
    this.USER_SET(await UserService.userPut(data));
  }

  @Action
  async updateUserLanguage (language: Language) {
    this.USER_SET(await UserService.userPut({ language }));
  }

  @Action
  public async updateProfileEmail (data: UserEmailPut) {
    this.USER_SET(await UserService.userEmailPut(data));
  }

  @Action
  public async updateProfilePassword (data: UserPasswordPut) {
    await UserService.userPasswordPut(data);
  }

  @Action
  public async reFetchCurrentUser (): Promise<User> {
    const user = await UserService.userGet();
    this.USER_SET(user);
    return user;
  }

  @Mutation
  public TOGGLE_PROMPT_LOGIN (state: boolean) {
    this.prompt.login = state;
    this.prompt.loginActiveTab = TAB_LOGIN;
  }

  @Mutation
  public TOGGLE_PROMPT_FORGOT_PASSWORD (state: boolean) {
    this.prompt.login = state;
    this.prompt.loginActiveTab = TAB_PASSWORD_RESET;
  }

  @Mutation
  public SET_AUTH_ACTIVE_TAB (tab: number) {
    this.prompt.loginActiveTab = tab;
  }

  @Mutation
  public ERRORS_CAPTCHA_UNSET () {
    this.errorCaptcha = {};
  }

  @Mutation
  public ERRORS_REGISTER_UNSET () {
    this.errorRegister = {};
  }

  @Mutation
  public ERRORS_LOGIN_UNSET () {
    this.errorLogin = {};
  }

  @Mutation
  public USER_SET (user: User) {
    this.authenticated = true;
    this.prompt.login = false;
    this.user = { ...this.user, ...user };
  }

  @Mutation
  private CAPTCHA_SET_REQUIRED (email: string) {
    this.loginCaptchaRequired = true;
    this.lastEmailLoginAttempt = email;
  }

  @Mutation
  private CAPTCHA_SET_NOT_REQUIRED () {
    this.loginCaptchaRequired = false;
  }

  @Mutation
  private ERRORS_CLEAR () {
    this.errorCaptcha = this.errorLogin = this.errorRegister = {};
  }

  @Mutation
  private ERRORS_CAPTCHA_SET (error: Generic422) {
    this.errorCaptcha = error;
  }

  @Mutation
  private ERRORS_REGISTER_SET (error: Generic422) {
    this.errorRegister = error;
  }

  @Mutation
  private ERRORS_LOGIN_SET () {
    this.errorLogin = {
      errors: ['errors.loginDetailsIncorrect'],
    };
  }

  @Mutation
  private USER_UNSET () {
    this.authenticated = false;
    this.user = {
      email: '',
      username: '',
      language: Language.EnGB,
      firstName: '',
      lastName: '',
      captchaLocked: false,
      verified: false,
    };
  }
}
