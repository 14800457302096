







































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { BusinessAccountStore } from '@/store';
import { Domain } from '@/api/ms-business-account-mgmt/services/interfaces';
import ODomainValidation from '@/components/organisms/forms/ODomainValidation.vue';
import {
  BriefcaseIcon,
  ChevronsLeftIcon,
  ChevronsRightIcon,
  HomeIcon,
  LinkIcon,
  PieChartIcon,
  SidebarIcon,
  TagIcon,
  XIcon
} from 'vue-feather-icons';
import EventBus, { EventBusEvents } from '@/EventBus';
import { clone } from 'common-utils/object';

@Component({
  components: {
    ODomainValidation,
    BriefcaseIcon,
    ChevronsLeftIcon,
    ChevronsRightIcon,
    HomeIcon,
    LinkIcon,
    PieChartIcon,
    SidebarIcon,
    TagIcon,
    XIcon
  },
})
export default class OSidebar extends Vue {
  routeNames = RouteNames;
  open = true;
  userToggledSidebar = false;
  eventCallerId = 'OSidebar';

  get selectedUrl () {
    return BusinessAccountStore.getSelectedDomain;
  }

  get selectedAccount(){
    return BusinessAccountStore.getSelectedAccount
  }

  $refs!: {
    sidebar: HTMLElement
  };

  domainLinks = {
    sidebar: [
      {
        display: true,
        routeName: RouteNames.ROUTE_SIDEBAR,
        text: this.$t('route.sidebarSettings')
      },
      {
        display: true,
        routeName: RouteNames.ROUTE_SIDEBAR_STYLE,
        text: this.$t('route.sidebarStyle')
      }
    ],
    statistics: [
      {
        display: true,
        routeName: RouteNames.ROUTE_YOUR_COMPETITORS,
        text: this.$t('route.yourCompetitors')
      },
      {
        display: true,
        routeName: RouteNames.ROUTE_STATISTICS,
        text: this.$t('route.statistics')
      }
    ],
    customerInteraction: [
      {
        display: true,
        routeName: RouteNames.ROUTE_COMMENTS,
        text: this.$t('route.comments')
      },
      {
        display: true,
        routeName: RouteNames.ROUTE_DIRECT_COMMENTS,
        text: this.$t('route.directComments')
      },
      {
        display: true,
        routeName: RouteNames.ROUTE_B2C_MESSAGING,
        text: this.$t('route.b2c_messaging')
      }
    ]
  };

  get accountsOwned () {
    return BusinessAccountStore.getAccountsOwned;
  }

  get domains (): Domain[] {
    return BusinessAccountStore.getDomains;
  }

  get getNoDomainTitle () {
    return this.domains.length ? '' : 'You must link a domain to this account to continue.';
  }

  async created () {}

  mounted () {
    this.setOpen();
    this.eventsBind();
  }

  destroyed () {
    this.eventsUnbind();
  }

  eventsBind () {
    window.addEventListener('resize', this.setOpen);
    EventBus.$on(EventBusEvents.EV_ACCOUNT_CHANGE, this.eventCallerId, this.handleAccountChange);
  }

  eventsUnbind () {
    window.removeEventListener('resize', this.setOpen);
    EventBus.$remove(EventBusEvents.EV_ACCOUNT_CHANGE, this.eventCallerId);
  }

  permittedRouteWhenNotValidated (routeName: string): boolean {
    return !this.selectedUrl?.validated && [
      this.routeNames.ROUTE_VALIDATE_INSTALL,
      this.routeNames.ROUTE_CONFIG
    ].includes(routeName as RouteNames);
  }

  isCurrentRoute (routeName: string): string {
    return this.$route.name === routeName ? 'is-active' : '';
  }

  toggleSidebar (): void {
    this.open = !this.open;
    this.userToggledSidebar = true;
  }

  isMobileScreenSize (): boolean {
    return window.matchMedia('screen and (max-width: 1024px)').matches;
  }

  setOpen () {
    this.open = this.userToggledSidebar ? this.open : !this.isMobileScreenSize();
  }

  async navigateToDomainValidation () {
    if (this.$route.name !== RouteNames.ROUTE_VALIDATE_INSTALL) {
      await this.$router.push({
        name: RouteNames.ROUTE_VALIDATE_INSTALL
      });
    }
  }

  async handleDomainChange (selectedDomain: Domain): Promise<void> {
    const domain = BusinessAccountStore.getDomains.find((domain) => {
      return domain.origin === selectedDomain.origin;
    });
    if (domain) {
      BusinessAccountStore.SET_SELECTED_DOMAIN(domain);
      if (!this.selectedUrl?.validated) {
        await this.navigateToDomainValidation();
      }
    }
  }

  async handleAccountChange (account) {
    await BusinessAccountStore.SET_SELECTED_ACCOUNT(clone(account));
    await BusinessAccountStore.fetchDomains();

    if (this.domains.length) {
      await BusinessAccountStore.SET_SELECTED_DOMAIN(this.domains[0]);
    }
  }

  async handleAccountNavigation (name: RouteNames) {
    await this.navigate(name);
  }

  async handleDomainNavigation (name: RouteNames) {
    if (!this.domains.length) {
      return;
    }

    // check if the selected domain is validated, else show the validation modal
    if (!this.selectedUrl?.validated && !this.permittedRouteWhenNotValidated(name)) {
      await this.navigateToDomainValidation();
    } else {
      await this.navigate(name);
    }
  }

  async navigate (name: RouteNames) {
    if (this.isMobileScreenSize()) {
      this.open = false;
    }

    await this.$router.push({ name });
  }

  @Watch('open')
  openValueHandle (newVal) {
    if (!newVal) {
      this.$refs.sidebar.classList.add('sidebar-closed');
    } else {
      this.$refs.sidebar.classList.remove('sidebar-closed');
    }
  }
}
