

























































import { Component, Vue } from 'vue-property-decorator';
import EventBus, { EventBusEvents } from '@/EventBus';
import UserService from '@/api/ms-business-account-mgmt/services/UserService';
import { RouteNames } from '@/router/RouteNames';
import { BusinessAccountStore } from '@/store';

import OLoginForm from '@/components/organisms/forms/OLoginForm.vue';
import MAuthenticationTabs from '@/components/molecules/MAuthenticationTabs.vue';

@Component({
  components: { MAuthenticationTabs, OLoginForm }
})
export default class TLogin extends Vue {
  created () {
    EventBus.$on(EventBusEvents.EV_LOGIN, 'TLogin.vue', async () => {
      // redirect based on the account type of the current user
      const isOwnerOrManager = await UserService.userIsDomainOwnerOrManagerGet();
      let name = RouteNames.ROUTE_DASHBOARD;
      if (!isOwnerOrManager.pass) {
        name = RouteNames.ROUTE_CREATE_ACCOUNT;
      } else {
        await BusinessAccountStore.fetchAccountsAndDomainsAndSet();
      }
      await this.$router.push({ name });
    });
  }
}
