










import { Component, Vue } from 'vue-property-decorator';
import ONavbar from '@/components/organisms/ONavbar.vue';
import OSidebar from '@/components/organisms/OSidebar.vue';
import MChatBot from '@/components/molecules/MChatBot.vue';
import TCreateAccount from '@/components/templates/TCreateAccount.vue';
import userIsPermittedToLoadDashboard from '@/utils/userIsPermittedToLoadDashboard';
import { AuthenticationStore } from '@/store';

@Component({
  components: {
    TCreateAccount,
    MChatBot,
    OSidebar,
    ONavbar
  },
})
export default class TMainLayoutAuthenticated extends Vue {
  showSideBar = false;

  created () {
    this.showSideBar = userIsPermittedToLoadDashboard(AuthenticationStore.getCurrentUser);
  }
}
