































import { Component, Vue } from 'vue-property-decorator';
import AGoogleIcon from '@/components/atoms/icon/AGoogleIcon.vue';
import { SSOGoogle } from '@/utils/SSOGoogle';
import { AuthenticationStore } from '@/store';
import { RouteNames } from '@/router/RouteNames';

@Component({
  components: {
    AGoogleIcon
  }
})
export default class MGoogleButton extends Vue {
  originalMsg = this.$t('sso.google');
  message = this.originalMsg;

  async signIn () {
    try {
      this.message = this.$t('dict.loading') + '...';

      const resp = await SSOGoogle();
      await AuthenticationStore.loginWithGoogle(resp);
      await this.$router.push({ name: RouteNames.ROUTE_DASHBOARD });
    } catch (e) {
      this.message = this.originalMsg;
    }
  }
}
