





















import { Component, Prop, Vue } from 'vue-property-decorator';
import AImage from '@/components/atoms/AImage.vue';
import { Size } from '@/api/ms-image-server-cache/services/interfaces/ImageTypeDirectorySizeFileNameGetPath';

@Component({
  components: {
    AImage
  }
})
export default class AUserProfilePic extends Vue {
  @Prop({required: true})
  private username!: string;
  @Prop(String)
  private imageSize?: Size;

  profilePicUrl () {
    return 'user/profile-pic/' + this.username + '.png';
  }
}
