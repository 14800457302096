export interface ImageTypeDirectorySizeFileNameGetPath {
  /**
   * Expects only digits or profile-pic
   */
  directory: string;
  /**
   * The name of the file, should be alphanumeric + . + - with the optional ratio block and
   * then ending .png
   */
  fileName: string;
  /**
   * 800x will return the image scaled to 800 wide, smaller images would have a white border
   * mx800x will return the image scaled down to 800 wide, image already smaller than 800 wide
   * would return the original image The opposite is also true, x400 will return the image
   * scaled down to 400 height.
   */
  size: Size;
  /**
   * The service the image is from
   */
  type: Type;
}

/**
 * 800x will return the image scaled to 800 wide, smaller images would have a white border
 * mx800x will return the image scaled down to 800 wide, image already smaller than 800 wide
 * would return the original image The opposite is also true, x400 will return the image
 * scaled down to 400 height.
 */
export enum Size {
  Mx1280 = 'mx1280',
  Mx800X = 'mx800x',
  The100X100 = '100x100',
  The200X = '200x',
  The200X200 = '200x200',
  The32X32 = '32x32',
  The400X = '400x',
  The600X = '600x',
  The60X60 = '60x60',
  The800X = '800x',
  The950X = '950x',
  X200 = 'x200',
  X300 = 'x300',
  X400 = 'x400',
  X400mx = 'x400mx',
  X600 = 'x600',
}

/**
 * The service the image is from
 */
export enum Type {
  Business = 'business',
  Channel = 'channel',
  Item = 'item',
  Recommendation = 'recommendation',
  UrlThumbnail = 'url-thumbnail',
  User = 'user',
}
