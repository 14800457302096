















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { Account, AccountPost } from '@/api/ms-business-account-mgmt/services/interfaces';
import { AuthenticationStore, BusinessAccountStore } from '@/store';
import AccountService from '@/api/ms-business-account-mgmt/services/AccountService';
import AuthenticationService from '@/services/AuthenticationService';
import userHasPermittedRoles from '@/utils/userHasPermittedRoles';

import AButtonSubmit from '@/storybook-components/src/stories/atoms/AButtonSubmit.vue';
import MInputWithValidation from '@/storybook-components/src/stories/molecules/MInputWithValidation.vue';
import AFormServerError from '@/components/atoms/AFormServerError.vue';
import MGoogleAutoComplete from '@/components/molecules/MGoogleAutoComplete.vue';
import AAutocomplete from '@/components/atoms/AAutocomplete.vue';
import MAddressCard from '@/components/molecules/MAddressCard.vue';
import AHeading2 from '@/components/atoms/AHeading2.vue';

@Component({
  components: {
    AHeading2,
    MInputWithValidation,
    AAutocomplete,
    AButtonSubmit,
    AFormServerError,
    MAddressCard,
    MGoogleAutoComplete,
    ValidationObserver,
    ValidationProvider,
  }
})
export default class OBusinessAccountUpsert extends Vue {
  @Prop({ type: String })
  accountEditId?: string;

  @Prop({ default: false })
  requireAddress!: boolean;

  @Prop({ default: false })
  hideHeader!: boolean;
  form: AccountPost = {
    name: '',
    owner: '',
    mainAddress: {
      streetNumberHouseName: '',
      addressLine1: '',
      addressLine2: '',
      unitNumber: '',
      country: '',
      stateProvinceRegion: '',
      zipPostalCode: ''
    },
    membership: []
  };
  serverError: any = null;
  isSaving = false;

  get getSubmitDisabledStatus (): boolean {
    return this.requireAddress && (!this.form.mainAddress.streetNumberHouseName || !this.form.mainAddress.addressLine1);
  }

  created () {
    this.form.owner = AuthenticationStore.getCurrentUser.username;
    this.setupForm();
  }

  @Watch('accountEditId')
  accountEditIdChangeHandle () {
    console.log('test');
    this.setupForm();
  }

  setupForm () {
    if (this.accountEditId) {
      const accountToEdit = BusinessAccountStore.getAccountsOwned.filter((account: Account) => {
        return account._id === this.accountEditId;
      });
      if (accountToEdit) {
        this.form = accountToEdit[0];
      }
    }
  }

  async onSubmit () {
    if (this.getSubmitDisabledStatus) {
      return;
    }
    this.isSaving = true;
    let savedAccount;
    try {
      if (this.accountEditId) {
        // create the account
        savedAccount = await AccountService.accountIdPut(this.form, { id: this.accountEditId }, {});
      } else {
        // create the account
        savedAccount = await AccountService.accountPost(this.form);
      }
      BusinessAccountStore.SET_SELECTED_ACCOUNT(savedAccount);

      // refetch the user which will pick up the new roles
      await this.refetchAndCheck();

      // Now fetch and set the domains and load the dashboard
      await BusinessAccountStore.fetchAccountsAndDomainsAndSet();
      this.isSaving = false;

      // last but not least, emit to the parent the completed status
      this.$emit('child-output', BusinessAccountStore.accounts);
    } catch (e: any) {
      console.error(e);
      this.serverError = e.response.data;
      this.isSaving = false;
    }
  }

  async refetchAndCheck (tryCount = 0): Promise<void> {
    // It was a success, re-fetch the JWT
    await AuthenticationStore.renewAccessToken({
      renewalToken: AuthenticationService.getRenewalJWT() as string
    });
    // refetch the current user
    const user = await AuthenticationStore.reFetchCurrentUser();

    // The user should now have the new role assigned
    if (!userHasPermittedRoles(user) && tryCount <= 5) {
      ++tryCount;
      await this.pause();
      return this.refetchAndCheck(tryCount);
    }
  }

  pause () {
    return new Promise<void>((resolve) => {
      setTimeout(() => resolve(), 500);
    });
  }

  handleMessageClosed () {
    this.serverError = null;
  }
}
