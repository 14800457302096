import HttpService from '@/services/HttpService';
import objectReduceByMap from 'object-reduce-by-map';

import { Domain } from './interfaces/Domain';
import { DomainIdCommentsGetPath } from './interfaces/DomainIdCommentsGetPath';
import { DomainIdDefaultImagePostFormData } from './interfaces/DomainIdDefaultImagePostFormData';
import { DomainIdDefaultImagePostPath } from './interfaces/DomainIdDefaultImagePostPath';
import { DomainIdGetPath } from './interfaces/DomainIdGetPath';
import { DomainIdInsightsDataChannelOverviewGetPath } from './interfaces/DomainIdInsightsDataChannelOverviewGetPath';
import { DomainIdInsightsDataChannelOverviewGetQuery } from './interfaces/DomainIdInsightsDataChannelOverviewGetQuery';
import { DomainIdInsightsDataItemsGetPath } from './interfaces/DomainIdInsightsDataItemsGetPath';
import { DomainIdInsightsDataItemsGetQuery } from './interfaces/DomainIdInsightsDataItemsGetQuery';
import { DomainIdInstallationCodeGetPath } from './interfaces/DomainIdInstallationCodeGetPath';
import { DomainIdInstallationCodeGetQuery } from './interfaces/DomainIdInstallationCodeGetQuery';
import { DomainIdManagerAddPatch } from './interfaces/DomainIdManagerAddPatch';
import { DomainIdManagerAddPatchPath } from './interfaces/DomainIdManagerAddPatchPath';
import { DomainIdManagerRemovePatch } from './interfaces/DomainIdManagerRemovePatch';
import { DomainIdManagerRemovePatchPath } from './interfaces/DomainIdManagerRemovePatchPath';
import { DomainIdProfileImagePutFormData } from './interfaces/DomainIdProfileImagePutFormData';
import { DomainIdProfileImagePutPath } from './interfaces/DomainIdProfileImagePutPath';
import { DomainIdPut } from './interfaces/DomainIdPut';
import { DomainIdPutPath } from './interfaces/DomainIdPutPath';
import { DomainIdSidebarSettingsCustomCssPut } from './interfaces/DomainIdSidebarSettingsCustomCssPut';
import { DomainIdSidebarSettingsCustomCssPutPath } from './interfaces/DomainIdSidebarSettingsCustomCssPutPath';
import { DomainIdSidebarSettingsInitialStatePut } from './interfaces/DomainIdSidebarSettingsInitialStatePut';
import { DomainIdSidebarSettingsInitialStatePutPath } from './interfaces/DomainIdSidebarSettingsInitialStatePutPath';
import { DomainIdSidebarSettingsProductPagesPut } from './interfaces/DomainIdSidebarSettingsProductPagesPut';
import { DomainIdSidebarSettingsProductPagesPutPath } from './interfaces/DomainIdSidebarSettingsProductPagesPutPath';
import { DomainIdSidebarSettingsPromoteUrlsPut } from './interfaces/DomainIdSidebarSettingsPromoteUrlsPut';
import { DomainIdSidebarSettingsPromoteUrlsPutPath } from './interfaces/DomainIdSidebarSettingsPromoteUrlsPutPath';
import { DomainIdSidebarSettingsResetGetPath } from './interfaces/DomainIdSidebarSettingsResetGetPath';
import { DomainIdSidebarSettingsTabsPut } from './interfaces/DomainIdSidebarSettingsTabsPut';
import { DomainIdSidebarSettingsTabsPutPath } from './interfaces/DomainIdSidebarSettingsTabsPutPath';
import { DomainIdSidebarSettingsUnauthenticatedPut } from './interfaces/DomainIdSidebarSettingsUnauthenticatedPut';
import { DomainIdSidebarSettingsUnauthenticatedPutPath } from './interfaces/DomainIdSidebarSettingsUnauthenticatedPutPath';
import { DomainIdSidebarSettingsWishlistControlsPut } from './interfaces/DomainIdSidebarSettingsWishlistControlsPut';
import { DomainIdSidebarSettingsWishlistControlsPutPath } from './interfaces/DomainIdSidebarSettingsWishlistControlsPutPath';
import { DomainIdValidationCheckGetPath } from './interfaces/DomainIdValidationCheckGetPath';
import { DomainIdValidationCheckPost } from './interfaces/DomainIdValidationCheckPost';
import { DomainIdValidationCheckPostPath } from './interfaces/DomainIdValidationCheckPostPath';
import { DomainInstallationCode } from './interfaces/DomainInstallationCode';
import { DomainPost } from './interfaces/DomainPost';
import { DomainSidebarSettings } from './interfaces/DomainSidebarSettings';
import { DomainValidationCheckHash } from './interfaces/DomainValidationCheckHash';
import { Domains } from './interfaces/Domains';
import { Image } from './interfaces/Image';
import { ItemChannelss } from './interfaces/ItemChannelss';
import { Items } from './interfaces/Items';

export default class DomainService {
  public static basePath = '/__/ms-business-account-mgmt/';

  /**
   *  Operation ID: domainGet
   *  Summary: Get all domains related to user
   *  Description: Get all domains related to a user or if company admin with correct permissions
   */
  public static domainGet(): Promise<Domains> {
    return HttpService.sendRequest({
      method: 'GET',
      path: DomainService.basePath + 'domain',
    });
  }

  /**
   *  Operation ID: domainPost
   *  Summary: Create a new domain
   *  Description: Create a new domain for a given account id
   */
  public static domainPost(body: DomainPost): Promise<Domain> {
    // definitions.DomainPost
    const mapbody = {
      permittedIps: [String],
      accountId: String,
      managers: [
        {
          firstName: String,
          lastName: String,
          username: String,
          createdAt: String,
        },
      ],
      active: Boolean,
      origin: String,
      sidebarSettings: {
        addItemTargets: {
          icon: {
            active: {
              d: String,
              fill: String,
              stroke: String,
              strokeWidth: String,
              strokeLinecap: String,
              strokeLinejoin: String,
            },
            inactive: {
              d: String,
              fill: String,
              stroke: String,
              strokeWidth: String,
              strokeLinecap: String,
              strokeLinejoin: String,
            },
          },
          targets: [
            {
              name: String,
              urlTarget: String,
              target: String,
              autoZIndexOff: Boolean,
              css: {
                position: String,
                top: String,
                bottom: String,
                left: String,
                right: String,
                width: String,
                margin: String,
              },
              link: String,
            },
          ],
        },
        attentionSeekers: [
          {
            uuid: String,
            triggers: [
              {
                triggerType: String,
                triggerValue: Number,
                triggerTarget: String,
              },
            ],
            authenticated: String,
            pageType: String,
            action: String,
            limit: Number,
            resetAfterDays: Number,
          },
        ],
        customCSS: String,
        initialStateOpen: Boolean,
        featureSwitch: {
          addItemExcludeUrls: [String],
          addItemIncludeUrls: [String],
          addItemIncludeCssSelector: [String],
          addItemExcludeCssSelector: [String],
        },
        promoteUrlsTitle: String,
        promoteUrls: [{ url: String }],
        openType: {
          openControlType: String,
          logoTargets: [
            {
              targetName: String,
              target: String,
              append: Boolean,
              height: Number,
              classes: String,
              style: String,
              stroke: String,
              strokeWidth: String,
            },
          ],
        },
        position: String,
        showFriendsItems: {
          noResultsText: String,
          fetch: String,
          order: String,
          show: Boolean,
          sort: String,
          position: Number,
        },
        showOwnItems: {
          noResultsText: String,
          fetch: String,
          order: String,
          show: Boolean,
          sort: String,
          position: Number,
        },
        showOthersItems: {
          noResultsText: String,
          fetch: String,
          order: String,
          show: Boolean,
          sort: String,
          position: Number,
        },
        unauthenticated: {
          openerText: String,
          openerText2: String,
          openerText3: String,
          nonProductPageImage: {
            urlType: String,
            linkUrl: String,
            url: String,
          },
        },
      },
    };

    return HttpService.sendRequest({
      method: 'POST',
      path: DomainService.basePath + 'domain',
      body: objectReduceByMap(body, mapbody),
    });
  }

  /**
   *  Operation ID: domainSkeletonGet
   *  Summary: Returns a domain with skeleton values
   *  Description:
   */
  public static domainSkeletonGet(): Promise<DomainPost> {
    return HttpService.sendRequest({
      method: 'GET',
      path: DomainService.basePath + 'domain/skeleton',
    });
  }

  /**
   *  Operation ID: domainIdCommentsGet
   *  Summary: Gets all items from the db with b2b comments
   *  Description:
   */
  public static domainIdCommentsGet(
    pathParams: DomainIdCommentsGetPath
  ): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: DomainService.basePath + 'domain/:id/comments',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdDefaultImagePost
   *  Summary: Upload a default image
   *  Description: Saves a new image, and returns the reference to it on the write server. Trigger the garbage collector for the previously saved image

   */
  public static domainIdDefaultImagePost(
    formData: DomainIdDefaultImagePostFormData,
    pathParams: DomainIdDefaultImagePostPath
  ): Promise<Image> {
    return HttpService.sendRequest({
      method: 'POST',
      path: DomainService.basePath + 'domain/:id/default-image',
      formData,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdGet
   *  Summary: Get domain details
   *  Description: Gets the general settings for a given domain
   */
  public static domainIdGet(pathParams: DomainIdGetPath): Promise<Domain> {
    return HttpService.sendRequest({
      method: 'GET',
      path: DomainService.basePath + 'domain/:id',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdPut
   *  Summary: Get domain details
   *  Description: Gets the general settings for a given domain
   */
  public static domainIdPut(
    body: DomainIdPut,
    pathParams: DomainIdPutPath
  ): Promise<Domain> {
    // definitions.DomainPut
    const mapbody = {
      permittedIps: [String],
      accountId: String,
      managers: [
        {
          firstName: String,
          lastName: String,
          username: String,
          createdAt: String,
        },
      ],
      active: Boolean,
      origin: String,
      sidebarSettings: {
        addItemTargets: {
          icon: {
            active: {
              d: String,
              fill: String,
              stroke: String,
              strokeWidth: String,
              strokeLinecap: String,
              strokeLinejoin: String,
            },
            inactive: {
              d: String,
              fill: String,
              stroke: String,
              strokeWidth: String,
              strokeLinecap: String,
              strokeLinejoin: String,
            },
          },
          targets: [
            {
              name: String,
              urlTarget: String,
              target: String,
              autoZIndexOff: Boolean,
              css: {
                position: String,
                top: String,
                bottom: String,
                left: String,
                right: String,
                width: String,
                margin: String,
              },
              link: String,
            },
          ],
        },
        attentionSeekers: [
          {
            uuid: String,
            triggers: [
              {
                triggerType: String,
                triggerValue: Number,
                triggerTarget: String,
              },
            ],
            authenticated: String,
            pageType: String,
            action: String,
            limit: Number,
            resetAfterDays: Number,
          },
        ],
        customCSS: String,
        initialStateOpen: Boolean,
        featureSwitch: {
          addItemExcludeUrls: [String],
          addItemIncludeUrls: [String],
          addItemIncludeCssSelector: [String],
          addItemExcludeCssSelector: [String],
        },
        promoteUrlsTitle: String,
        promoteUrls: [{ url: String }],
        openType: {
          openControlType: String,
          logoTargets: [
            {
              targetName: String,
              target: String,
              append: Boolean,
              height: Number,
              classes: String,
              style: String,
              stroke: String,
              strokeWidth: String,
            },
          ],
        },
        position: String,
        showFriendsItems: {
          noResultsText: String,
          fetch: String,
          order: String,
          show: Boolean,
          sort: String,
          position: Number,
        },
        showOwnItems: {
          noResultsText: String,
          fetch: String,
          order: String,
          show: Boolean,
          sort: String,
          position: Number,
        },
        showOthersItems: {
          noResultsText: String,
          fetch: String,
          order: String,
          show: Boolean,
          sort: String,
          position: Number,
        },
        unauthenticated: {
          openerText: String,
          openerText2: String,
          openerText3: String,
          nonProductPageImage: {
            urlType: String,
            linkUrl: String,
            url: String,
          },
        },
      },
    };

    return HttpService.sendRequest({
      method: 'PUT',
      path: DomainService.basePath + 'domain/:id',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdInsightsDataChannelOverviewGet
   *  Summary: Fetch all unique channels for a given URL
   *  Description:
   */
  public static domainIdInsightsDataChannelOverviewGet(
    pathParams: DomainIdInsightsDataChannelOverviewGetPath,
    query: DomainIdInsightsDataChannelOverviewGetQuery
  ): Promise<ItemChannelss> {
    return HttpService.sendRequest({
      method: 'GET',
      path:
        DomainService.basePath + 'domain/:id/insights-data/channel-overview',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: domainIdInsightsDataItemsGet
   *  Summary: Fetch items
   *  Description: Fetches a list of items based on search criteria
   */
  public static domainIdInsightsDataItemsGet(
    pathParams: DomainIdInsightsDataItemsGetPath,
    query: DomainIdInsightsDataItemsGetQuery
  ): Promise<Items> {
    return HttpService.sendRequest({
      method: 'GET',
      path: DomainService.basePath + 'domain/:id/insights-data/items',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: domainIdInstallationCodeGet
   *  Summary: Get domain installation code
   *  Description: Fetched from the backend for easier control later
   */
  public static domainIdInstallationCodeGet(
    pathParams: DomainIdInstallationCodeGetPath,
    query: DomainIdInstallationCodeGetQuery
  ): Promise<DomainInstallationCode> {
    return HttpService.sendRequest({
      method: 'GET',
      path: DomainService.basePath + 'domain/:id/installation-code',
      params: pathParams,
      qs: query,
    });
  }

  /**
   *  Operation ID: domainIdManagerAddPatch
   *  Summary: Add a new domain manager
   *  Description: Adds a new domain manager to the domain
   */
  public static domainIdManagerAddPatch(
    body: DomainIdManagerAddPatch,
    pathParams: DomainIdManagerAddPatchPath
  ): Promise<Domain> {
    // definitions.DomainManager
    const mapbody = {
      firstName: String,
      lastName: String,
      username: String,
      createdAt: String,
    };

    return HttpService.sendRequest({
      method: 'PATCH',
      path: DomainService.basePath + 'domain/:id/manager/add',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdManagerRemovePatch
   *  Summary: Remove a domain manager
   *  Description: Removes a domain manager from the domain
   */
  public static domainIdManagerRemovePatch(
    body: DomainIdManagerRemovePatch,
    pathParams: DomainIdManagerRemovePatchPath
  ): Promise<Domain> {
    // definitions.DomainManager
    const mapbody = {
      firstName: String,
      lastName: String,
      username: String,
      createdAt: String,
    };

    return HttpService.sendRequest({
      method: 'PATCH',
      path: DomainService.basePath + 'domain/:id/manager/remove',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdProfileImagePut
   *  Summary: Upload a domain profile image
   *  Description: Uploads a profile image for a given domain
   */
  public static domainIdProfileImagePut(
    formData: DomainIdProfileImagePutFormData,
    pathParams: DomainIdProfileImagePutPath
  ): Promise<void> {
    return HttpService.sendRequest({
      method: 'PUT',
      path: DomainService.basePath + 'domain/:id/profile-image',
      formData,
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdSidebarSettingsCustomCssPut
   *  Summary: Set the custom CSS
   *  Description:
   */
  public static domainIdSidebarSettingsCustomCssPut(
    body: DomainIdSidebarSettingsCustomCssPut,
    pathParams: DomainIdSidebarSettingsCustomCssPutPath
  ): Promise<Domain> {
    // definitions.DomainSidebarSettingsCustomCssPut
    const mapbody = { css: String };

    return HttpService.sendRequest({
      method: 'PUT',
      path: DomainService.basePath + 'domain/:id/sidebar-settings/custom-css',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdSidebarSettingsInitialStatePut
   *  Summary: Set the initial state of the sidebar
   *  Description:
   */
  public static domainIdSidebarSettingsInitialStatePut(
    body: DomainIdSidebarSettingsInitialStatePut,
    pathParams: DomainIdSidebarSettingsInitialStatePutPath
  ): Promise<Domain> {
    // definitions.DomainSidebarSettingsInitialStatePut
    const mapbody = {
      position: String,
      initialStateOpen: Boolean,
      openType: {
        openControlType: String,
        logoTargets: [
          {
            targetName: String,
            target: String,
            append: Boolean,
            height: Number,
            classes: String,
            style: String,
            stroke: String,
            strokeWidth: String,
          },
        ],
      },
    };

    return HttpService.sendRequest({
      method: 'PUT',
      path:
        DomainService.basePath + 'domain/:id/sidebar-settings/initial-state',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdSidebarSettingsProductPagesPut
   *  Summary: Set the product page urls or selectors
   *  Description:
   */
  public static domainIdSidebarSettingsProductPagesPut(
    body: DomainIdSidebarSettingsProductPagesPut,
    pathParams: DomainIdSidebarSettingsProductPagesPutPath
  ): Promise<Domain> {
    // definitions.DomainSidebarSettingsFeatureSwitchPut
    const mapbody = {
      addItemExcludeUrls: [String],
      addItemIncludeUrls: [String],
      addItemIncludeCssSelector: [String],
      addItemExcludeCssSelector: [String],
    };

    return HttpService.sendRequest({
      method: 'PUT',
      path:
        DomainService.basePath + 'domain/:id/sidebar-settings/product-pages',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdSidebarSettingsPromoteUrlsPut
   *  Summary: Add new urls to auto promote for the given url id
   *  Description:
   */
  public static domainIdSidebarSettingsPromoteUrlsPut(
    body: DomainIdSidebarSettingsPromoteUrlsPut,
    pathParams: DomainIdSidebarSettingsPromoteUrlsPutPath
  ): Promise<Domain> {
    // definitions.DomainSidebarSettingsPromoteUrlsPut
    const mapbody = { promoteUrlsTitle: String, data: [{ url: String }] };

    return HttpService.sendRequest({
      method: 'PUT',
      path: DomainService.basePath + 'domain/:id/sidebar-settings/promote-urls',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdSidebarSettingsResetGet
   *  Summary: Gets a default domain sidebar settings object
   *  Description: Does not set the object, but merely give the default. It is behind the {id} in the path for potential future domain specific defaults. For now though everyone gets the same default

   */
  public static domainIdSidebarSettingsResetGet(
    pathParams: DomainIdSidebarSettingsResetGetPath
  ): Promise<DomainSidebarSettings> {
    return HttpService.sendRequest({
      method: 'GET',
      path: DomainService.basePath + 'domain/:id/sidebar-settings/reset',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdSidebarSettingsTabsPut
   *  Summary: Set the details of the sidebar tabs
   *  Description:
   */
  public static domainIdSidebarSettingsTabsPut(
    body: DomainIdSidebarSettingsTabsPut,
    pathParams: DomainIdSidebarSettingsTabsPutPath
  ): Promise<Domain> {
    // definitions.DomainSidebarSettingsTabsPut
    const mapbody = {
      showFriendsItems: {
        noResultsText: String,
        fetch: String,
        order: String,
        show: Boolean,
        sort: String,
        position: Number,
      },
      showOwnItems: {
        noResultsText: String,
        fetch: String,
        order: String,
        show: Boolean,
        sort: String,
        position: Number,
      },
      showOthersItems: {
        noResultsText: String,
        fetch: String,
        order: String,
        show: Boolean,
        sort: String,
        position: Number,
      },
    };

    return HttpService.sendRequest({
      method: 'PUT',
      path: DomainService.basePath + 'domain/:id/sidebar-settings/tabs',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdSidebarSettingsUnauthenticatedPut
   *  Summary: Set the details of the unauthenticated state
   *  Description:
   */
  public static domainIdSidebarSettingsUnauthenticatedPut(
    body: DomainIdSidebarSettingsUnauthenticatedPut,
    pathParams: DomainIdSidebarSettingsUnauthenticatedPutPath
  ): Promise<Domain> {
    // definitions.DomainSidebarSettingsUnauthenticatedPut
    const mapbody = {
      openerText: String,
      openerText2: String,
      openerText3: String,
      nonProductPageImage: { urlType: String, linkUrl: String, url: String },
    };

    return HttpService.sendRequest({
      method: 'PUT',
      path:
        DomainService.basePath + 'domain/:id/sidebar-settings/unauthenticated',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdSidebarSettingsWishlistControlsPut
   *  Summary: Set wishlist controls, ie the hearts and their positioning
   *  Description:
   */
  public static domainIdSidebarSettingsWishlistControlsPut(
    body: DomainIdSidebarSettingsWishlistControlsPut,
    pathParams: DomainIdSidebarSettingsWishlistControlsPutPath
  ): Promise<Domain> {
    // definitions.DomainSidebarSettingsAddItemTargetsPut
    const mapbody = {
      icon: {
        active: {
          d: String,
          fill: String,
          stroke: String,
          strokeWidth: String,
          strokeLinecap: String,
          strokeLinejoin: String,
        },
        inactive: {
          d: String,
          fill: String,
          stroke: String,
          strokeWidth: String,
          strokeLinecap: String,
          strokeLinejoin: String,
        },
      },
      targets: [
        {
          name: String,
          urlTarget: String,
          target: String,
          autoZIndexOff: Boolean,
          css: {
            position: String,
            top: String,
            bottom: String,
            left: String,
            right: String,
            width: String,
            margin: String,
          },
          link: String,
        },
      ],
    };

    return HttpService.sendRequest({
      method: 'PUT',
      path:
        DomainService.basePath +
        'domain/:id/sidebar-settings/wishlist-controls',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdValidationCheckGet
   *  Summary: Get validation file
   *  Description: Returns a hash relative to the domain, this is used as the validation check. It will be a filename, either a txt file or an image.

   */
  public static domainIdValidationCheckGet(
    pathParams: DomainIdValidationCheckGetPath
  ): Promise<DomainValidationCheckHash> {
    return HttpService.sendRequest({
      method: 'GET',
      path: DomainService.basePath + 'domain/:id/validation-check',
      params: pathParams,
    });
  }

  /**
   *  Operation ID: domainIdValidationCheckPost
   *  Summary: Trigger the validation check
   *  Description: Requires a path to the registered domain
   */
  public static domainIdValidationCheckPost(
    body: DomainIdValidationCheckPost,
    pathParams: DomainIdValidationCheckPostPath
  ): Promise<void> {
    // definitions.DomainValidationCheckPost
    const mapbody = { path: String };

    return HttpService.sendRequest({
      method: 'POST',
      path: DomainService.basePath + 'domain/:id/validation-check',
      body: objectReduceByMap(body, mapbody),
      params: pathParams,
    });
  }
}
