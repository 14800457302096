import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import { INonPersistedStateModule } from './interfaces/NonPersistedStateModule';

@Module({
  name: StoreNames.NON_PERSISTED_STATE_STORE,
  namespaced: true,
})
export default class NonPersistedStateModule extends VuexModule implements INonPersistedStateModule {
  @Mutation
  RESET (): void {
  }

  integrationMode = {
    on: false,
    domain: '',
    onDisabledMessage: 'Please load the main Liffery admin to edit this setting'
  };

  get getIntegrationModeOn (): boolean {
    return this.integrationMode.on;
  }

  get getIntegrationModeDomain (): string {
    return this.integrationMode.domain;
  }

  @Mutation
  SET_INTEGRATION_MODE_STATE_ON (domain: string): void {
    this.integrationMode.on = true;
    this.integrationMode.domain = domain;
  }
}
