

























import { Component, Vue } from 'vue-property-decorator';
import { TAB_LOGIN, TAB_REGISTER } from '@/constantsAndEnums/tabs';
import OLoginForm from '@/components/organisms/forms/OLoginForm.vue';
import ORegisterForm from '@/components/organisms/forms/ORegisterForm.vue';
import { AuthenticationStore } from '@/store';

@Component({
  components: {
    OLoginForm,
    ORegisterForm,
  },
})
export default class MAuthenticationTabs extends Vue {
  private TAB_REGISTER = TAB_REGISTER;
  private TAB_LOGIN = TAB_LOGIN;

  showForgotPassword: boolean = false

  get activeTab() {
    return AuthenticationStore.prompt.loginActiveTab;
  }

  set activeTab(tab: number) {
    AuthenticationStore.SET_AUTH_ACTIVE_TAB(tab);
  }

  setShowForgotPassword (value: boolean) {
    this.showForgotPassword = value
  }
}
