import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreNames } from '@/store/modules/enums/StoreNames';
import {
  IBusinessAccountModule,
  IBusinessAccountModuleActivity
} from '@/store/modules/interfaces/BusinessAccountModule';
import AccountService from '@/api/ms-business-account-mgmt/services/AccountService';
import {
  Account,
  Domain,
  DomainIdManagerAddPatch,
  DomainPut
} from '@/api/ms-business-account-mgmt/services/interfaces';
import DomainService from '@/api/ms-business-account-mgmt/services/DomainService';
import { selectedAccountDummy, selectedDomainDummy } from '@/store/modules/constants/BusinessAccountConstants';

@Module({
  name: StoreNames.BUSINESS_ACCOUNT_STORE,
  namespaced: true,
})
export default class BusinessAccountModule extends VuexModule implements IBusinessAccountModule {
  @Mutation
  RESET () {
    this.activity.initialised = false;
    this.accounts = [];
    this.domains = [];
    this.allDomains = [];
    this.selectedAccount = selectedAccountDummy;
    this.selectedDomain = selectedDomainDummy;
    this.urlPreviewLoading = false;
  }

  activity: { initialised: boolean } = {
    initialised: false
  };
  accounts: Account[] = [];
  domains: Domain[] = [];
  allDomains: Domain[] = [];
  selectedAccount: Account = selectedAccountDummy;
  selectedDomain: Domain = selectedDomainDummy;
  urlPreviewLoading: boolean = false;

  get getSelectedAccount () {
    return this.selectedAccount;
  }

  get getActivity () {
    return this.activity;
  }

  get getAccountsOwned (): Account[] {
    // for now, don't filter by owner
    // return this.accounts.filter((account: Account) => {
    //   return account.owner === AuthenticationStore.getCurrentUser.username;
    // });
    return this.accounts;
  }

  get getSelectedAccountForDomain () {
    if (this.getSelectedDomain && this.domains.length > 0) {
      const account = this.accounts.filter((account: Account) => {
        return account._id === this.getSelectedDomain?.accountId;
      });
      return account[0];
    } else {
      return this.accounts[0];
    }
  }

  get getAllDomains (): Domain[] {
    return this.allDomains;
  }

  get getDomains (): Domain[] {
    return this.domains;
  }

  get getSelectedDomain (): Domain | undefined {
    return this.selectedDomain;
  }

  get getUrlPreviewLoading (): boolean {
    return this.urlPreviewLoading;
  }

  @Action
  async fetchAccounts () {
    const { data } = await AccountService.accountGet({});
    this.SET_ACCOUNTS(data);

    if (!this.selectedAccount && data.length) {
      this.SET_SELECTED_ACCOUNT(data[0]);
    }
  }

  @Action
  async fetchAccountsAndDomainsAndSet () {
    try {
      // fetch the domains and load the
      await this.fetchAccounts();
      await this.fetchDomains();

      // if we are in integration mode check we have required domain for the current user
      if (this.domains.length > 0) {
        let index = 0;
        if (this.getSelectedDomain) {
          index = this.domains.findIndex(domain => domain._id === this.getSelectedDomain?._id);
          index = index !== -1 ? index : 0;
        }
        this.SET_SELECTED_DOMAIN(this.domains[index]);
      }
      this.SET_ACTIVITY({ initialised: true });
      return true;
    } catch (e: any) {
      this.SET_ACTIVITY({ initialised: true });
      return false;
    }
  }

  @Action
  async fetchDomains () {
    const selectedAccountId = this.selectedAccount?._id;
    const { data } = await DomainService.domainGet();
    this.SET_ALL_DOMAINS(data);

    if (selectedAccountId) {
      const domains = data.filter(domain => domain.accountId === selectedAccountId);
      this.SET_DOMAINS(domains);
    }
  }

  @Action({ rawError: true })
  async updateDomain (input: { domain: DomainPut, id: string }) {
    try {
      const savedDomain = await DomainService.domainIdPut(input.domain, { id: input.id });
      this.SET_SELECTED_DOMAIN(savedDomain);
    } catch (e: any) {
      console.log(e);
      throw e;
    }
  }

  @Action
  async addManagerToDomain (input: { member: DomainIdManagerAddPatch, id: string }) {
    const resp = await DomainService.domainIdManagerAddPatch(input.member, { id: input.id });
    this.SET_SELECTED_DOMAIN(resp);

    return resp;
  }

  @Action
  async removeManagerFromDomain (input: { member: DomainIdManagerAddPatch, id: string }) {
    this.SET_SELECTED_DOMAIN(await DomainService.domainIdManagerRemovePatch(input.member, { id: input.id }));
  }

  @Mutation
  SET_ACTIVITY (activity: IBusinessAccountModuleActivity) {
    this.activity = activity;
  }

  @Mutation
  SET_ACCOUNTS (accounts: Account[]) {
    this.accounts = accounts;
  }

  @Mutation
  SET_ALL_DOMAINS (domains: Domain[]) {
    this.allDomains = domains;
  }

  @Mutation
  SET_DOMAINS (domains: Domain[]) {
    this.domains = domains;
  }

  @Mutation
  SET_SELECTED_DOMAIN (domain: Domain) {
    // the full domain is updated into the store
    for (let i = 0; i < this.domains.length; ++i) {
      if (this.domains[i]._id === domain._id) {
        this.domains[i] = domain;
      }
    }
    // now mark the current as active
    this.selectedDomain = domain;
  }

  @Mutation
  SET_SELECTED_ACCOUNT (account: Account) {
    console.log(account);
    this.selectedAccount = account;
    console.log(account);
  }

  @Mutation
  SET_URL_PREVIEW_LOADING (value: boolean) {
    this.urlPreviewLoading = value;
  }
}
