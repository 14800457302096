import HttpService from '@/services/HttpService';

import { GenericMessage } from './interfaces/GenericMessage';
import { PasswordResetPost } from './interfaces/PasswordResetPost';
import { PasswordResetRequestPost } from './interfaces/PasswordResetRequestPost';

export default class PasswordService {
  public static basePath = '/__/ms-authentication/';

  /**
   *  Operation ID: passwordResetPost
   *  Summary: Create a reset, from password
   *  Description: Resets a password for a user based on email and password reset password sent via email
   */
  public static passwordResetPost(
    body: PasswordResetPost
  ): Promise<GenericMessage> {
    return HttpService.sendRequest({
      method: 'POST',
      path: PasswordService.basePath + 'password/reset',
      body,
    });
  }

  /**
   *  Operation ID: passwordResetRequestPost
   *  Summary: Create a request, from reset, from password
   *  Description: Request password reset secret to email address
   */
  public static passwordResetRequestPost(
    body: PasswordResetRequestPost
  ): Promise<GenericMessage> {
    return HttpService.sendRequest({
      method: 'POST',
      path: PasswordService.basePath + 'password/reset/request',
      body,
    });
  }
}
