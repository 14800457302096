




import { Component, Vue } from 'vue-property-decorator';
import MChatBotHelpCrunch from '@/components/molecules/MChatBotHelpCrunch.vue';

@Component({
  components: {
    MChatBotHelpCrunch
  }
})
export default class MChatBot extends Vue {}
