









import { Component, Prop, Vue } from 'vue-property-decorator';
import { RouteNames } from '@/router/RouteNames';
import { ExternalLinkIcon } from 'vue-feather-icons';

@Component({
  components: {
    ExternalLinkIcon
  }
})
export default class ARouterLinkLifferyContact extends Vue {
  @Prop(String)
  username!: string;
  @Prop(Boolean)
  hasIcon!: boolean;

  name = RouteNames.ROUTE_SETTINGS_SECURITY;
}
