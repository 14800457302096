











import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AHeading2 extends Vue {}
