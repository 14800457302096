export enum RouteNames {
  ROUTE_ACCOUNT = 'accounts',
  ROUTE_ACTIVATE_ACCOUNT = 'activateAccount',
  ROUTE_ADD_ITEM_CONTROLS = 'addItemControls',
  ROUTE_ADD_ITEM_RESTRICTION = 'addItemRestriction',
  ROUTE_B2C_MESSAGING = 'b2cMessaging',
  ROUTE_COMMENTS = 'comments',
  ROUTE_CONFIG = 'config',
  ROUTE_CREATE_ACCOUNT = 'createAccount',
  ROUTE_DASHBOARD = 'dashboard',
  ROUTE_DIRECT_COMMENTS = 'directComments',
  ROUTE_INDEX = 'index',
  ROUTE_LOGIN = 'login',
  ROUTE_LOST = 'lost',
  ROUTE_NEW_DOMAIN = 'newDomain',
  ROUTE_PASSWORD_RESET = 'passwordReset',
  ROUTE_SETTINGS = 'settings',
  ROUTE_SETTINGS_GENERAL = 'settingsGeneral',
  ROUTE_SETTINGS_SECURITY = 'settingsSecurity',
  ROUTE_SIDEBAR = 'sidebar',
  ROUTE_SIDEBAR_STYLE = 'sidebarStyle',
  ROUTE_STATISTICS = 'statistics',
  ROUTE_VALIDATE_INSTALL = 'install_validate',
  ROUTE_YOUR_COMPETITORS = 'route_your_competitors',
}
